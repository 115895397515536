import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AnimatePresence, motion, useMotionValue } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import ImageComponent from "./ImageComponent";
import updateArtWallProducts from "../actions/artWall/updateArtWallProducts";
import setMinimizeArtWallProductSidebar from "../actions/artWall/setMinimizeArtWallProductSidebar";
import setArtWallActiveProduct from "../actions/artWall/setArtWallActiveProduct";
import { bindActionCreators } from "redux";
import { fadeAnimation } from "../animations";
import useWindowSize from "../hooks/useWindowSize";
import setPosterOnTop from "../actions/artWall/setPosterOnTop";
import updateArtWallProduct from "../actions/artWall/updateArtWallProduct";
import classNames from "classnames";
import useCanvasSize from "../hooks/useCanvasSize";
import { has } from "lodash";
import useMeasure from "react-use-measure";
import { warpFragmentRefresh } from "../modules/ajaxAddToCart";

const ArtWallPoster = ({
  artWallProduct,
  artWallProducts,
  actions,
  dragConstraints,
  isOnTop,
  index,
  search,
  minimizeArtWallProductSidebar,
}) => {
  console.log("HOLO artWallProduct", artWallProduct);
  const [posterRef, posterRect] = useMeasure();

  const contextMenuRef = useRef(null);

  const [hasHover, setHover] = useState(false);

  const [posterPosition, setPosterPosition] = useState(null);

  const [moveContextMenuToLeft, setMoveContextMenuToLeft] = useState(false);

  const [contextMenuWidth, setContextMenuWidth] = useState(null);

  const [scaledWidth, setScaledWidth] = useState(null);
  const [scaledHeight, setScaledHeight] = useState(null);

  const windowSize = useWindowSize();
  const canvasSize = useCanvasSize(dragConstraints);

  const [imgSrc, setImageSrc] = useState(null);

  // const [hasMoved, setHasMoved] = useState(false)
  const hasMoved = useMotionValue(false);

  // const [tempRect, tempRef] = useClientRect()

  useEffect(() => {
    let imgSrc;

    switch (artWallProduct.dimension) {
      case "landscape":
        imgSrc = artWallProduct.landscapeImage;
        break;

      case "square":
        imgSrc = artWallProduct.squareImage;
        break;

      default:
        imgSrc = artWallProduct.posterImage;
        break;
    }

    setImageSrc(imgSrc);
  }, []);

  useEffect(() => {
    if (hasHover) {
      setContextMenuWidth(contextMenuRef.current.clientWidth);
    }
  }, [hasHover]);

  useEffect(() => {
    if ((canvasSize.width, canvasSize.height)) {
      const trueWallWidthInCM = 437;
      const constant = canvasSize.width / trueWallWidthInCM;

      const { x, y } = artWallProduct.selectedSize;
      // console.log('x, y', x,',', y);
      // console.log('constant', constant);
      // console.log('calc', (parseInt(x) / 10) * constant);

      const scaledPosterWidthTempVar =
        artWallProduct.dimension === "landscape"
          ? (parseInt(y) / 10) * constant
          : (parseInt(x) / 10) * constant;
      setScaledWidth(scaledPosterWidthTempVar);

      const scaledPosterHeightTempVar =
        artWallProduct.dimension === "landscape"
          ? (parseInt(x) / 10) * constant
          : (parseInt(y) / 10) * constant;
      setScaledHeight(scaledPosterHeightTempVar);
    }
  }, [canvasSize.width, canvasSize.height, artWallProduct.selectedSize]);

  useEffect(() => {
    if (posterPosition !== null && posterPosition.x) {
      if (
        windowSize.width - (posterPosition.x + scaledWidth) <=
        contextMenuWidth * 1.5
      ) {
        setMoveContextMenuToLeft(true);
      } else {
        setMoveContextMenuToLeft(false);
      }
    }
  }, [posterPosition]);

  const removeFromArtWallHandler = () => {
    actions.updateArtWallProducts({
      product: artWallProduct,
      artWallProducts,
      type: "remove",
      search,
    });
    jQuery.ajax(warpFragmentRefresh);
    if (!minimizeArtWallProductSidebar) {
      actions.setMinimizeArtWallProductSidebar(true);
    }
  };

  const editPosterHandler = (artWallProduct) => {
    actions.setMinimizeArtWallProductSidebar(false);
    actions.setArtWallActiveProduct(artWallProduct);
  };

  const addPositionToPosterHandler = async (id) => {
    const posterEl = document.getElementById(id);
    console.log("getBoundingClientRect id", posterEl.getBoundingClientRect());
    // if (posterRef?.current) {
    //   console.log(posterRef.current.getBoundingClientRect())
    // }
    // console.log('info', info);
    // console.log('posterRect', posterRect);

    const { x, y } = posterEl.getBoundingClientRect();

    const updatedArtWallProducts = await actions.updateArtWallProduct({
      product: {
        ...artWallProduct,
        position: {
          x: x - 400,
          y: y - 60,
        },
      },
      artWallProducts,
    });
    console.log(
      "updatedArtWallProducts",
      updatedArtWallProducts.artWallProducts
    );

    await actions.updateArtWallProducts({
      artWallProducts: updatedArtWallProducts.artWallProducts,
      type: "set",
    });

    // await actions.setArtWallScreenshotStatus('generating')

    // localStorage.setItem('artWallProducts', JSON.stringify(updatedArtWallProductsForLocalStorage))
  };

  // const disableSelectText = () => {
  //   return false
  // }

  const onDragStartHandler = (uuid) => {
    hasMoved.set(true);
    console.log("drag start", "hasMoved", hasMoved);
    actions.setPosterOnTop(uuid);
  };

  const onDragEndHandler = (uuid) => {
    console.log("drag end", "hasMoved", hasMoved);
    addPositionToPosterHandler(uuid);
  };

  const onTapHandler = (artWallProduct) => {
    console.log("tap. ", "hasMoved", hasMoved);

    if (hasMoved.get()) {
      hasMoved.set(false);
    } else {
      editPosterHandler(artWallProduct);
      hasMoved.set(false);
    }

    // if (hasMoved) {
    //   return
    // } else {
    //   editPosterHandler(artWallProduct)
    //   setHasMoved(false)
    // }
  };

  const onTapStartHandler = (uuid) => {
    console.log("tap start", "hasMoved", hasMoved);
    actions.setPosterOnTop(uuid);
  };

  // console.log('canvasSize.height', canvasSize.height);
  // console.log('scaledHeight', scaledHeight);

  // console.log(artWallProducts);

  // useEffect(() => {
  //   console.log('posterRect', posterRect);
  //   // actions.updateArtWallProduct({
  //   //   product: {
  //   //     ...artWallProduct,
  //   //     position: {
  //   //       x: info.x - 400,
  //   //       y: info.y - 60,
  //   //     }
  //   //   },
  //   //   artWallProducts
  //   // })
  // }, [])

  // useEffect(() => {
  //   console.log('artWallProduct', artWallProduct);
  // }, [artWallProduct])

  const calculateWidthPosition = (index) => {
    // console.log('index', index);
    if (index < 5)
      return canvasSize.width / 2 - canvasSize.width / 5 + index * 40;
    // console.log('remainder', index % 5);
    return canvasSize.width / 2 - canvasSize.width / 5 + (index % 5) * 40;
  };

  const calculateHeightPosition = (index) => {
    // console.log('index', index);
    if (index < 5) return canvasSize.height / 2 - scaledHeight + index * 40;
    // console.log('remainder', index % 5);
    return canvasSize.height / 2 - scaledHeight + (index % 5) * 40;
  };

  return (
    <motion.div
      ref={posterRef}
      id={artWallProduct.uuid}
      className={classNames(
        "inline-block absolute",
        artWallProduct?.selectedFrame?.selectedVariationImage && "shadow-lg"
      )}
      style={{
        zIndex: isOnTop ? index + 999 : index,
        x:
          search && has(artWallProduct, "position.x")
            ? artWallProduct.position.x
            : has(artWallProduct, "position.x")
            ? artWallProduct.position.x
            : calculateWidthPosition(index),
        y:
          search && has(artWallProduct, "position.y")
            ? artWallProduct.position.y
            : has(artWallProduct, "position.y")
            ? artWallProduct.position.y
            : calculateHeightPosition(index),
        width: scaledWidth,
        height: scaledHeight,
      }}
      // initial="initial"
      // animate="animate"
      // exit="exit"
      // variants={fadeAnimation}
      drag
      dragConstraints={dragConstraints}
      dragElastic={false}
      dragMomentum={false}
      onHoverStart={() => setHover(true)}
      onHoverEnd={() => setHover(false)}
      onDrag={(event, info) => setPosterPosition(info.point)}
      onDragStart={(e) => onDragStartHandler(artWallProduct.uuid)}
      onDragEnd={(e) => onDragEndHandler(artWallProduct.uuid)}
      onTap={(e) => onTapHandler(artWallProduct)}
      onTapStart={(e) => onTapStartHandler(artWallProduct.uuid)}
      whileHover={{ cursor: "grab" }}
      whileTap={{ cursor: "grabbing" }}
      whileDrag={{ cursor: "grabbing" }}
      onMouseDown={(e) => e.preventDefault()}
    >
      <div
        className="relative"
        style={{ zIndex: 1, width: scaledWidth, height: scaledHeight }}
      >
        {/* <ImageComponent src={artWallProduct.posterImage} width={scaledWidth} height={scaledHeight} /> */}
        <div
          style={{ width: scaledWidth, height: scaledHeight, padding: "2px" }}
        >
          <img
            src={imgSrc}
            width={scaledWidth}
            height={scaledHeight}
            loading="lazy"
            alt=""
            className={classNames(
              "block pointer-events-none w-full h-full",
              !artWallProduct?.selectedFrame?.selectedVariationImage &&
                "shadow-lg"
            )}
          />
        </div>
        {artWallProduct?.selectedFrame?.selectedVariationImage && (
          <div className={`absolute top-0 left-0 w-full h-full`}>
            {/* <ImageComponent src={artWallProduct?.selectedFrame?.selectedVariationImage} width={scaledWidth} height={scaledHeight} /> */}
            <img
              src={artWallProduct?.selectedFrame?.selectedVariationImage}
              width={
                artWallProduct.dimension === "landscape"
                  ? scaledHeight
                  : scaledWidth
              }
              height={
                artWallProduct.dimension === "landscape"
                  ? scaledWidth
                  : scaledHeight
              }
              loading="lazy"
              alt=""
              className={`block pointer-events-none`}
              style={{
                transform:
                  artWallProduct.dimension === "landscape"
                    ? `rotate(90deg) translate(-${
                        (scaledWidth - scaledHeight) / 2
                      }px, -${(scaledWidth - scaledHeight) / 2}px)`
                    : `rotate(0deg)`,
              }}
            />
          </div>
        )}
      </div>
      <AnimatePresence>
        {hasHover && (
          <motion.div
            className={classNames(
              "absolute bottom-0 z-0",
              { "left-0": moveContextMenuToLeft },
              { "right-0": !moveContextMenuToLeft }
            )}
            style={{
              padding: moveContextMenuToLeft
                ? `0 ${contextMenuWidth}px 0 0`
                : `0 0 0 ${contextMenuWidth}px`,
            }}
            initial={{
              opacity: 0,
              x: moveContextMenuToLeft
                ? contextMenuWidth * 1.2 * -1
                : contextMenuWidth * 1.2,
            }}
            animate={{
              opacity: 1,
              x: moveContextMenuToLeft
                ? contextMenuWidth * 1.5 * -1
                : contextMenuWidth * 1.5,
            }}
            exit={{
              opacity: 0,
              x: moveContextMenuToLeft
                ? contextMenuWidth * 1.2 * -1
                : contextMenuWidth * 1.2,
            }}
            transition={{ type: "tween", ease: "easeInOut" }}
          >
            <div
              ref={contextMenuRef}
              className="flex flex-col justify-center items-center bg-black rounded-md"
              style={{ width: "28px", height: "60px" }}
            >
              <div
                className="flex justify-center items-center pb-2 cursor-pointer"
                style={{ padding: "6px 2px 8px 5px" }}
                onClick={() => editPosterHandler(artWallProduct)}
              >
                <FontAwesomeIcon
                  icon={["fal", "edit"]}
                  className="text-white text-xs"
                />
              </div>
              <div
                className="flex justify-center items-center pt-2 cursor-pointer border-t border-solid border-gray-light"
                style={{ padding: "8px 6px 6px" }}
                onClick={() => removeFromArtWallHandler()}
              >
                <FontAwesomeIcon
                  icon={["fal", "trash-alt"]}
                  className="text-white text-xs"
                />
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

const mapStateToProps = (state) => {
  const props = {
    artWallProducts: state.artWall.artWallProducts,
    search: state.router.location.search,
    minimizeArtWallProductSidebar: state.artWall.minimizeArtWallProductSidebar,
  };

  return props;
};

const mapDispatchToProps = (dispatch) => {
  const actions = {
    updateArtWallProducts,
    setMinimizeArtWallProductSidebar,
    setArtWallActiveProduct,
    setPosterOnTop,
    updateArtWallProduct,
  };

  const actionMap = {
    actions: bindActionCreators(actions, dispatch),
    dispatch,
  };

  return actionMap;
};

export default connect(mapStateToProps, mapDispatchToProps)(ArtWallPoster);
