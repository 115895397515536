import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useEmblaCarousel from 'embla-carousel-react'
import { AnimatePresence, motion } from 'framer-motion';
import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import setActiveArtWallBackground from "../actions/artWall/setActiveArtWallBackground"
import setShowEditRoomModal from "../actions/artWall/setShowEditRoomModal"
import { fadeAnimation } from '../animations';

const ArtWallEditRoomModalComponent = ({actions}) => {
  const [selectedArtWallBackground, setSelectedArtWallBackground] = useState(false)

  const [emblaRef, emblaApi] = useEmblaCarousel({
    slidesToScroll: 2,
    skipSnaps: false,
    align: 'start',
    speed: 15,
  })
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [scrollSnaps, setScrollSnaps] = useState([])

  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev()
  }, [emblaApi])

  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext()
  }, [emblaApi])

  const scrollTo = useCallback((index) => emblaApi && emblaApi.scrollTo(index), [emblaApi])

  const onSelect = useCallback(() => {
    if (!emblaApi) return
    setSelectedIndex(emblaApi.selectedScrollSnap())
    // setPrevBtnEnabled(emblaApi.canScrollPrev())
    // setNextBtnEnabled(emblaApi.canScrollNext())
  }, [emblaApi, setSelectedIndex])

  useEffect(() => {
    if (!emblaApi) return
    onSelect()
    setScrollSnaps(emblaApi.scrollSnapList())
    emblaApi.on("select", onSelect)
  }, [emblaApi, setScrollSnaps, onSelect])

  const setActiveArtWallBackgroundHandler = () => {
    if (selectedArtWallBackground) {
      actions.setActiveArtWallBackground(selectedArtWallBackground)
      actions.setShowEditRoomModal(false)
    }
  }

  console.log(selectedArtWallBackground);

  return (
    <div className="flex flex-col py-8 bg-tpc-light relative" style={{width: '548px'}}>
      <div className="absolute top-0 right-0 m-6 p-2 cursor-pointer" onClick={() => actions.setShowEditRoomModal(false)}>
        <FontAwesomeIcon icon={['fal', 'times']} className="block text-xl" />
      </div>
      <div className="px-8 flex flex-col">
        <p className="font-display-regular text-sm text-black font-normal uppercase leading-none">Backgrounds</p>
      </div>

      <div className="relative">
        <div className="embla overflow-hidden mx-15 relative" ref={emblaRef}>
          <div className="embla__container flex -ml-13px" style={{padding: '10px 0 0 0'}}>
            {artWallWalls.map((artWallWall, index) =>
              <div key={index} className="embla__slide max-w-full pl-13px" style={{flex: '0 0 50%'}}>
                <div className="relative cursor-pointer" style={{padding: '6px'}} onClick={() => setSelectedArtWallBackground(artWallWall)}>
                  <AnimatePresence>
                    {selectedArtWallBackground?.art_wall_wall_image === artWallWall?.art_wall_wall_image &&
                      <motion.div
                        className="border border-solid border-black absolute inset-0"
                        // style={{top: '-7px', right: '-7px', bottom: '-7px', left: '-7px'}}
                        initial="initial"
                        animate="animate"
                        exit="exit"
                        variants={fadeAnimation}
                        transition={{type: 'tween'}}
                      />
                    }
                  </AnimatePresence>
                  <img className="block cursor-pointer" src={artWallWall.art_wall_wall_thumb_image_source[0]} alt={artWallWall.art_wall_wall_title} />
                  <p className="font-body-regular text-sm font-normal text-black mt-1 mb-0 px-2">{artWallWall.art_wall_wall_title}</p>
                  <p className="font-body-regular text-xs font-normal text-gray-light mb-0 px-2">{artWallWall.art_wall_wall_description}</p>
                </div>
              </div>
            )}
          </div>
          <div className="embla__dots flex justify-center -ml-2 mt-2">
            {scrollSnaps.map((scrollSnap, index) => (
              <div className="pl-2" key={index}>
                <button
                  className={`embla__dot block h-2 w-2 rounded-full ${index === selectedIndex ? 'bg-black' : 'bg-tpc-darker'}`}
                  onClick={() => scrollTo(index)}
                />
              </div>
            ))}
          </div>
        </div>
        <button className="embla__prev absolute top-1/2 left-8 transform -translate-y-1/2" onClick={scrollPrev}>
          <FontAwesomeIcon icon={['fal', 'chevron-left']} className="block text-lg" />
        </button>
        <button className="embla__next absolute top-1/2 right-8 transform -translate-y-1/2" onClick={scrollNext}>
          <FontAwesomeIcon icon={['fal', 'chevron-right']} className="block text-lg" />
        </button>
      </div>
      
      <div className="px-8 flex flex-col">
        <motion.button
          className={`bg-black flex justify-center items-center h-11 self-center rounded-none mt-7 font-display-bold text-xs uppercase font-normal tracking-widest text-tpc-light ${selectedArtWallBackground ? 'bg-black' : 'bg-gray'} ${selectedArtWallBackground ? 'cursor-pointer' : 'cursor-not-allowed'} transition-colors duration-200 ease-in-out w-full`}
          style={{maxWidth: '340px'}}
          onClick={() => setActiveArtWallBackgroundHandler()}
          {...(selectedArtWallBackground && {onClick: () => setActiveArtWallBackgroundHandler()})}
          layout
        >
          Select
        </motion.button>
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  const actions = {
    setActiveArtWallBackground,
    setShowEditRoomModal,
  }

  const actionMap = {
    actions: bindActionCreators(actions, dispatch),
    dispatch
  }

  return actionMap
}

export default connect(null, mapDispatchToProps)(ArtWallEditRoomModalComponent)