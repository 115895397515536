import { useEffect, useState } from "react";

const useCanvasSize = (ref) => {
  const [canvasSize, setCanvasSize] = useState({
    width: undefined,
    height: undefined,
  })

  useEffect(() => {
    if (ref.current && window !== 'undefined') {
      const handleCanvasSize = () => {
        const boundingRect = ref.current.getBoundingClientRect()
        const { width, height } = boundingRect
  
        setCanvasSize({
          width: width,
          height: height,
        })
      }

      window.addEventListener('resize', handleCanvasSize)
     
      handleCanvasSize()
    
      return () => window.removeEventListener('resize', handleCanvasSize)
    }
  }, [ref])

  return canvasSize
}

export default useCanvasSize