import { Document, Font, Image, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { isEmpty } from "lodash";
import { twoDigits } from "../lib/parse";
import getCombinedArtWallProducts from "../utilities/GetCombinedArtWallProducts";

const ArtWallPDF = ({ artWallProducts, artWallScreenshot, artWallShareableUrl }) => {
  const styles = StyleSheet.create({
    section: {
      padding: 20,
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
    },
    sectionFluid: {
      padding: 20,
      display: 'flex',
      flexDirection: 'column',
    }
  });
  
  Font.register({ family: 'Verdana'})
  Font.register({ family: 'Georgia'})

  // const prints = artWallProducts?.filter(({categories}) => categories.some(category => category.slug !== 'frames'))
  // const frames = artWallProducts?.filter(({categories}) => categories.some(category => category.slug === 'frames'))

  const combined = getCombinedArtWallProducts(artWallProducts)

  console.log('combined', combined)

  const prices = artWallProducts?.map(({id, price, selectedVariationId, selectedVariationPrice}) => ({
    id: selectedVariationId ? selectedVariationId : id,
    price: selectedVariationPrice ? parseInt(selectedVariationPrice) : parseInt(price)
  }))

  const subtotal = prices?.reduce((sum, { price }) => sum + parseInt(price), 0)

  // const hasFrames = artWallProducts?.some(({categories}) => categories.some(category => category.slug === 'frames'))

  // let subtotalWithoutFrames = subtotal
  // if (hasFrames) {
  //   const excludesPricesArray = artWallProducts?.filter(({categories}) => categories.some(category => category.slug !== 'frames')).map(({id, price, selectedVariationId, selectedVariationPrice}) => ({
  //     id: selectedVariationId ? selectedVariationId : id,
  //     price: selectedVariationPrice ? parseInt(selectedVariationPrice) : parseInt(price)
  //   }))
  
  //   subtotalWithoutFrames = excludesPricesArray?.reduce((sum, { price }) => sum + parseInt(price), 0)
  // }

  return (
    <Document>
      <Page size="A4" orientation="landscape">
        <View style={styles.section}>
          <Text style={{fontSize: '10', margin: 0, lineHeight: 'normal', textTransform: 'uppercase', color: '#333333', letterSpacing: '1px', fontFamily: 'Georgia', marginBottom: '5mm'}}>Your new art wall</Text>
          {/* <View style={{width: '44', height: '1', backgroundColor: '#D2D2D2', margin: '10 0'}} /> */}
          <Image src={artWallScreenshot} style={{maxWidth: '100%', height: 'auto', objectFit: 'contain'}} />
          <Text style={{fontSize: '12', margin: 0, lineHeight: 'normal', color: '#787878', fontFamily: 'Times-Roman', margin: '5mm 0'}}>
            Go to Wall - {artWallShareableUrl}
          </Text>
        </View>
      </Page>
      <Page size="A4" style={styles.page}>
        <View style={styles.sectionFluid}>
          {/* <Svg viewBox="0 0 506 274" style={{fill: '#333333', width: 48, height: 26}}>
            <Path
              d="M72.415 151.728V65.553l49.687.687c3.825-2.425 4.525-19.45.7-23.275L72.415 46.09l1.037-44.137c-1.037-2.425-13.9-2.775-15.637 0-12.163 22.587-33.35 41.7-55.6 49.35-2.775 1.387-3.125 13.2 0 14.937h21.2l-2.775 99.384c0 28.154 21.887 45.879 52.475 45.879 29.187 0 52.812-20.163 61.5-45.18 1.387-6.612-7.3-11.12-12.163-6.95-7.637 13.905-17.725 19.117-27.8 19.117-12.85 0-22.237-9.041-22.237-26.762zm145.112-76.45l.688-36.838c-2.425-2.425-11.813-2.075-13.55 0-10.775 9.388-35.438 19.463-61.163 22.25-2.425 1.038-2.775 10.425 0 11.813 17.038 7.637 20.513 10.075 20.513 18.062v166.813l-17.375 2.775c-2.088 2.437-2.088 11.462 0 13.55h98.687c2.075-2.088 2.075-11.113 0-13.55l-27.8-2.775v-57.35c8.688 7.65 20.15 11.475 33.7 11.475 45.875 0 84.1-37.542 84.1-96.613 0-44.125-23.975-77.487-62.9-77.487-23.975 0-45.862 18.762-54.9 37.875zm0 10.075c5.563-9.375 15.638-17.025 28.15-17.025 21.888 0 34.738 23.625 34.738 62.55 0 39.258-17.025 62.9-36.825 62.9-11.475 0-21.9-9.388-26.063-26.067V85.353zM446.39 56.165c12.85 0 20.5 6.263 20.5 14.25 0 7.3-15.637 7.3-15.637 26.413 0 12.162 9.725 21.187 22.587 21.187 15.988 0 27.8-11.812 27.8-30.925 0-28.837-22.937-49.687-57.687-49.687-53.863 0-91.388 43.437-91.388 92.087 0 49.334 33.7 82.013 79.575 82.013 39.263 0 66.025-28.5 72.975-61.513-.35-4.862-7.3-7.3-11.812-4.862-10.775 18.408-25.375 25.708-43.788 25.708-26.762 0-50.387-19.808-50.387-57.683 0-34.4 22.237-56.988 47.262-56.988z"
            />
          </Svg> */}
          <Text style={{fontSize: '10', margin: 0, lineHeight: 'normal', textTransform: 'uppercase', color: '#333333', letterSpacing: '1px',  fontFamily: 'Georgia', marginBottom: '5mm'}}>Products</Text>
          <View style={{display: 'flex', flexDirection: 'row', backgroundColor: '#E1E0DC', padding: '4mm 5mm'}}>
            <Text style={{flex: 2, fontSize: '9', lineHeight: 'normal', textTransform: 'uppercase', color: '#333333', letterSpacing: '1px', fontFamily: 'Georgia'}}>Your Products</Text>
            <Text style={{flex: 1, fontSize: '9', lineHeight: 'normal', textTransform: 'uppercase', color: '#333333', letterSpacing: '1px', fontFamily: 'Georgia'}}>Size</Text>
            <Text style={{flex: 1, fontSize: '9', lineHeight: 'normal', textTransform: 'uppercase', color: '#333333', letterSpacing: '1px', fontFamily: 'Georgia'}}>Type</Text>
            <Text style={{fontSize: '9', lineHeight: 'normal', textTransform: 'uppercase', color: '#333333', letterSpacing: '1px', fontFamily: 'Georgia'}}>Price</Text>
          </View>
          <View>
            {combined.map((array, index) =>
              <View key={index} style={{position: 'relative', borderBottom: '1px solid #d2d2d2', padding: '5mm 0'}}>
                {array.map(({post_title, artist, selectedSize, price, selectedVariationPrice, categories, color, uuid}) =>
                  <View key={uuid} style={{padding: '0 5mm'}}>
                    <View
                      style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: categories.find(category => category.slug === 'frames') ? '5mm' : '0', borderLeft: categories.find(category => category.slug === 'frames') && array.length === 2 ? '1px solid #333333' : 'none', paddingLeft: categories.find(category => category.slug === 'frames') && array.length === 2 ? '12px' : '0'}}
                    >
                      <View style={{flex: 2, marginRight: categories.find(category => category.slug === 'frames') && array.length === 2 ? '-12px' : '0'}}>
                        <Text style={{fontSize: '10', lineHeight: 'normal', letterSpacing: 0.2, textTransform: 'uppercase', color: '#333333', fontFamily: 'Times-Roman'}}>{post_title}</Text>
                        <View style={{display: 'flex', marginTop: '2mm'}}>
                          {categories.find(category => category.slug === 'frames')
                            ? <Text style={{fontSize: '9', lineHeight: 'normal', color: '#787878', fontFamily: 'Times-Roman'}}>{color[0]?.name}</Text>
                            : (
                              !isEmpty(artist)
                                ? (artist.map((a, index) =>
                                  <Text style={{fontSize: '9', lineHeight: 'normal', color: '#787878', fontFamily: 'Times-Roman'}}>{a.name}</Text>
                                ))
                                : <Text style={{fontSize: '9', lineHeight: 'normal', color: '#787878', fontFamily: 'Times-Roman'}}>Unknown Artist</Text>
                            )
                          }
                        </View>
                      </View>
                      <Text style={{flex: 1, fontSize: '10', lineHeight: 'normal', letterSpacing: 0.2, textTransform: 'uppercase', color: '#787878', fontFamily: 'Times-Roman'}}>{selectedSize.name}</Text>
                      <Text style={{flex: 1, fontSize: '10', lineHeight: 'normal', letterSpacing: 0.2, textTransform: 'uppercase', color: '#787878', fontFamily: 'Times-Roman'}}>{categories.find(category => category.slug === 'frames') ? 'Frames' : 'Art Print'}</Text>
                      <Text style={{fontSize: '10', lineHeight: 'normal', letterSpacing: 0.2, textTransform: 'uppercase', color: '#333333', fontFamily: 'Times-Roman'}}>{`€ ${selectedVariationPrice ? twoDigits(parseInt(selectedVariationPrice)) : twoDigits(parseInt(price))}`}</Text>
                    </View>
                  </View>
                )}
              </View>
            )}
          </View>
          {/* {!isEmpty(frames) && 
            <>
              <View style={{display: 'flex', flexDirection: 'row', backgroundColor: '#E1E0DC'}}>
                <Text>Frames</Text>
                <Text>Size</Text>
                <Text>Price</Text>
              </View>
              <View style={styles.section}>
                {frames.map((frame) => (
                  <View style={{display: 'flex', flexDirection: 'row'}}>
                    <Text>{frame.post_title}</Text>
                    <Text>{frame.selectedSize.name}</Text>
                    <Text>€ {frame.selectedVariationPrice}</Text>
                  </View>
                ))}
              </View>
            </>
          } */}
          <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: '20px'}}>
            <Text style={{fontSize: '13', margin: 0, lineHeight: 'normal', textTransform: 'uppercase', color: '#333333', letterSpacing: '1px', fontFamily: 'Times-Roman', fontWeight: 'bold'}}>Subtotal</Text>
            <Text style={{fontSize: '13', margin: 0, lineHeight: 'normal', textTransform: 'uppercase', color: '#333333', letterSpacing: '1px', fontFamily: 'Times-Roman', fontWeight: 'bold'}}>€ {twoDigits(subtotal)}</Text>
          </View>
        </View>
      </Page>
    </Document>
  )
}

export default ArtWallPDF