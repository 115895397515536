import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ArtWallPoster from "./ArtWallPoster";
import setArtWallCanvasHeight from "../actions/artWall/setArtWallCanvasHeight";
import setArtWallCanvasWidth from "../actions/artWall/setArtWallCanvasWidth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import updateArtWallProducts from "../actions/artWall/updateArtWallProducts";
import setShowEditRoomModal from "../actions/artWall/setShowEditRoomModal";
import setArtWallCanvasRef from "../actions/artWall/setArtWallCanvasRef";
import { fadeAnimation } from "../animations";
import { isEmpty } from "lodash";
import setShowArtWallClearAllModal from "../actions/artWall/setShowArtWallClearAllModal";

const ArtWallCanvas = ({
  artWallProducts,
  posterOnTop,
  actions,
  activeArtWallBackground,
}) => {
  const [hasHover, setHover] = useState(false);
  const dragConstraint = useRef(null);

  useEffect(() => {
    actions.setArtWallCanvasRef(dragConstraint);

    actions.setArtWallCanvasWidth(dragConstraint.current.offsetWidth);
    actions.setArtWallCanvasHeight(dragConstraint.current.offsetHeight);
  }, []);

  const clearAllHandler = () => {
    actions.setShowArtWallClearAllModal(true);
  };

  // console.log('artWallProducts before filter', artWallProducts);
  console.log(
    "yololo",
    artWallProducts.filter(
      (artWallProduct, index) =>
        !artWallProduct.categories.some(
          (category) => category.slug === "frames"
        )
    )
  );

  return (
    <div className="relative w-full h-full z-0">
      <motion.div
        id="artwall-sc-container"
        ref={dragConstraint}
        className="h-full w-full"
        onHoverStart={() => setHover(true)}
        onHoverEnd={() => setHover(false)}
      >
        {!isEmpty(activeArtWallBackground) && (
          <img
            src={activeArtWallBackground.art_wall_wall_full_image_source[0]}
            className="absolute inset-0 h-full w-full select-none"
            style={{ objectFit: "cover", objectPosition: "bottom" }}
            draggable={false}
          />
        )}
        <AnimatePresence>
          {/* {!isEmpty(artWallProducts) && artWallProducts.map((artWallProduct, index) => {
            return !artWallProduct.categories.some(category => category.slug === 'frames') && <ArtWallPoster key={artWallProduct.uuid} index={index} artWallProduct={artWallProduct} dragConstraints={dragConstraint} isOnTop={artWallProduct.uuid === posterOnTop ? true : false} />
          }
          )} */}
          {!isEmpty(artWallProducts) &&
            artWallProducts
              .filter(
                (artWallProduct, index) =>
                  !artWallProduct.categories.some(
                    (category) => category.slug === "frames"
                  )
              )
              .map((artWallProduct, index) => (
                <ArtWallPoster
                  key={artWallProduct.uuid}
                  index={index}
                  artWallProduct={artWallProduct}
                  dragConstraints={dragConstraint}
                  isOnTop={artWallProduct.uuid === posterOnTop ? true : false}
                />
              ))}
          {hasHover && (
            <motion.div
              className="absolute top-0 right-0 flex py-2 bg-tpc-light m-8 z-9999"
              style={{ borderRadius: "4px" }}
              initial="initial"
              animate="animate"
              exit="exit"
              variants={fadeAnimation}
            >
              <div
                className="flex justify-center items-center cursor-pointer px-4 py-1 text-gray-light font-body-regular font-normal tracking-wide text-xs border-r border-solid border-gray-light"
                onClick={() => actions.setShowEditRoomModal(true)}
              >
                <FontAwesomeIcon
                  icon={["fal", "edit"]}
                  className="text-gray-light text-xs mr-1"
                  style={{ marginBottom: "1px" }}
                />
                Background
              </div>
              <div
                className="flex justify-center items-center cursor-pointer px-4 py-1 text-gray-light font-body-regular font-normal tracking-wide text-xs"
                onClick={() => clearAllHandler()}
              >
                <FontAwesomeIcon
                  icon={["fal", "trash-alt"]}
                  className="text-gray-light text-xs mr-1"
                  style={{ marginBottom: "1px" }}
                />
                Clear wall
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  const actions = {
    setArtWallCanvasHeight,
    updateArtWallProducts,
    setShowEditRoomModal,
    setArtWallCanvasRef,
    setArtWallCanvasWidth,
    setShowArtWallClearAllModal,
  };

  const actionMap = {
    actions: bindActionCreators(actions, dispatch),
    dispatch,
  };

  return actionMap;
};

const mapStateToProps = (state) => {
  const props = {
    artWallProducts: state.artWall.artWallProducts,
    posterOnTop: state.artWall.posterOnTop,
    activeArtWallBackground: state.artWall.activeArtWallBackground,
  };

  return props;
};

export default connect(mapStateToProps, mapDispatchToProps)(ArtWallCanvas);
