import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import axios from "axios"
import { AnimatePresence, motion } from "framer-motion"
import { useEffect, useState } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import setShowShareAndDownloadModal from "../actions/artWall/setShowShareAndDownloadModal"
import setFirstClick from "../actions/artWall/setFirstClick"
import setArtWallShareableUrl from "../actions/artWall/setArtWallShareableUrl"
import setArtWallProductsToBeShared from "../actions/artWall/setArtWallProductsToBeShared"
import { fadeAnimation } from "../animations"
import { isEmpty } from "lodash"
import { Document, Page, Text, View, StyleSheet, PDFDownloadLink, Image, Font, Svg, Path } from '@react-pdf/renderer'
import ArtWallSpinner from "./ArtWallSpinner"
import { twoDigits } from "../lib/parse"
import CopyToClipboard from "react-copy-to-clipboard"
import getCombinedArtWallProducts from "../utilities/GetCombinedArtWallProducts"
import { useAtom } from "jotai"
import { showShareModalAtom } from "../jotai"
import setArtWallScreenshotStatus from "../actions/artWall/setArtWallScreenshotStatus"
import setArtWallScreenshot from "../actions/artWall/setArtWallScreenshot"
import ArtWallPDF from "./ArtWallPDF"

const ArtWallShareModal = ({ artWallScreenshot, artWallCanvasWidth, artWallCanvasHeight, artWallProducts, actions, search, query, pathname, firstClick, artWallShareableUrl, artWallProductsToBeShared, artWallCanvasRef, artWallScreenshotStatus }) => {
  const [copyString, setCopyString] = useState('Copy')
  const [showUrl, setShowUrl] = useState(false)
  const [copied, setCopied] = useState(false)

  const [showShareModal, setShowShareModal] = useAtom(showShareModalAtom)

  console.log('copied', copied);

  useEffect(() => {
    if (copied) {
      setCopyString('Copied')
      setTimeout(() => {
        setCopied(false)
        setCopyString('Copy')
      }, 2500)
    }
  }, [copied])

  console.log('PRODUCTS', artWallProducts);

  useEffect(() => {
    if (isEmpty(artWallProductsToBeShared)) {
      actions.setArtWallProductsToBeShared(artWallProducts)
    }
  }, [])

  useEffect(() => {
    if (artWallCanvasRef.current === null) return

    const getSharableUrlAndScreenshot = async () => {
      const baseUrl = window.location.origin
      const urlResp = await axios.post(`${baseUrl}/wp-json/tpc/v1/share-art-wall`, {
        art_wall_value: artWallProducts
      })

      const awdUrl = window.location.href
      const constructedUrl = `${awdUrl}?h=${urlResp.data}`
      // const constructedUrl = 'https://theposterclub.com/art-wall-designer/?h=54be532531'
      actions.setArtWallShareableUrl(constructedUrl)

      if (artWallScreenshotStatus === 'generating') {          
        const screenshotResp = await axios.post(`https://share.theposterclub.com`, {
          url: constructedUrl,
          width: window.innerWidth,
          height: window.innerHeight,
        })

        // const screenshotResp = await axios.post(`http://127.0.0.1:3003`, {
        //   url: constructedUrl,
        //   width: window.innerWidth,
        //   height: window.innerHeight,
        // })

        actions.setArtWallScreenshotStatus('done')

        console.log('the screen resp url', screenshotResp)
  
        actions.setArtWallScreenshot(`${screenshotResp.data.file}`)
      }
    }
  
    getSharableUrlAndScreenshot()
  }, [artWallCanvasRef, artWallProducts, artWallScreenshotStatus])

  const shareHandler = (type) => {
    if (type === 'link') {
      setShowUrl(true)
    }

    console.log('yo', artWallProductsToBeShared.map(({uuid}) => uuid).sort().toString());
    console.log('lo', artWallProducts.map(({uuid}) => uuid).sort().toString());
    
    if (firstClick || artWallProductsToBeShared.map(({uuid}) => uuid).sort().toString() !== artWallProducts.map(({uuid}) => uuid).sort().toString()) {
      saveArtWallToDatabase(type)
    } else {
      if (type === 'sms') {
        window.location.href = `sms:?&body=Art Wall from The Poster Club ${artWallShareableUrl}`
      }

      if (type === 'mail') {
        window.location.href = `mailto:?subject=Art Wall from The Poster Club&body=Art Wall from The Poster Club ${artWallShareableUrl}`
      }
    }

    actions.setFirstClick(false)
  }

  const saveArtWallToDatabase = (type) => {
    console.log('pathname', pathname);
    console.log('query', query);
    console.log('search', search);
    const baseUrl = window.location.origin
    console.log('baseUrl', baseUrl);
    if (firstClick && search) {
      console.log('YOYO');
      const url = `${baseUrl+pathname+search}`
      actions.setArtWallShareableUrl(url)

      if (type === 'sms') {
        window.location.href = `sms:?&body=Art Wall from The Poster Club ${url}`
      }

      if (type === 'mail') {
        window.location.href = `mailto:?subject=Art Wall from The Poster Club&body=Art Wall from The Poster Club ${url}`
      }
    } else {
      console.log('LOLO');
      actions.setArtWallShareableUrl('Generating shareable link...')
      console.log('artWallProducts', artWallProducts);
      axios.post(`${baseUrl}/wp-json/tpc/v1/share-art-wall`, {
        art_wall_value: artWallProducts
      })
        .then(response => {
          console.log('response.data', response.data);
          const constructedUrl = `${baseUrl+pathname}?h=${response.data}`
          actions.setArtWallShareableUrl(constructedUrl)

          if (type === 'sms') {
            window.location.href = `sms:?&body=Favourites from The Poster Club ${constructedUrl}`
          }

          if (type === 'mail') {
            window.location.href = `mailto:?subject=Favourites from The Poster Club&body=Favourites from The Poster Club ${constructedUrl}`
          }
        })
        .catch(error => {
          console.log(error)
        })
      
      actions.setArtWallProductsToBeShared(artWallProducts)
    }
  }

  const closeShareModalHandler = () => {
    setShowShareModal(false)
  }

  return (
    <motion.div layout className="flex flex-col p-8 bg-tpc-light relative" style={{width: '770px'}}>
      <button className="absolute top-0 right-0 m-6 p-2 cursor-pointer" onClick={() => closeShareModalHandler()}>
        <FontAwesomeIcon icon={['fal', 'times']} className="block text-xl" />
      </button>
      <p className="font-display-regular text-sm text-black font-normal uppercase pb-5 leading-none tracking-wider mb-0">Your new art wall</p>
      <div className="flex items-center justify-center w-full h-[440px]">
        <AnimatePresence exitBeforeEnter>
          {((!artWallScreenshot && artWallScreenshotStatus === 'generating') || (artWallScreenshot && artWallScreenshotStatus !== 'done')) &&
            <motion.div
              key="spinner"
              initial="initial"
              animate="animate"
              exit="exit"
              variants={fadeAnimation}
            >
              <div>
                <p className="font-body-regular font-normal text-lg text-black tracking-wide mb-4">Hang on. Generating preview</p>
                <ArtWallSpinner hexColor="#333333" />
              </div>
            </motion.div>
          }
          {artWallScreenshot && artWallScreenshotStatus === 'done' &&
            <img src={artWallScreenshot} className="block object-contain max-w-full max-h-full" />
          }
        </AnimatePresence>
      </div>
      <div className="flex justify-between items-start mt-4">
        <div className="flex flex-col">
          <div className="flex items-center">
            <p className="font-body-regular text-xs font-normal text-gray-light mr-1 mb-0">Share</p>
            <div className="cursor-pointer" style={{padding: '0 4px', marginLeft: '6px'}} onClick={() => shareHandler('sms')}>
              <FontAwesomeIcon icon={['fal', 'comment']} className="block" />
            </div>
            <div className="cursor-pointer" style={{padding: '0 4px', marginLeft: '6px'}} onClick={() => shareHandler('mail')}>
              <FontAwesomeIcon icon={['fal', 'envelope']} className="block" />
            </div>
            <div className="cursor-pointer" style={{padding: '0 4px', marginLeft: '6px'}} onClick={() => shareHandler('link')}>
              <FontAwesomeIcon icon={['fal', 'link']} className="block" />
            </div>
          </div>
          <AnimatePresence>
            {showUrl &&
              <motion.div
                className="flex items-center p-2 bg-gray-copy bg-opacity-50 border border-solid border-gray-copy rounded-sm mt-4"
                initial="initial"
                animate="animate"
                exit="exit"
                variants={fadeAnimation}
              >
                <p className="font-body-regular text-xs font-normal text-gray-light mb-0">{artWallShareableUrl}</p>
                <CopyToClipboard text={artWallShareableUrl} onCopy={() => setCopied(true)}>
                  <button className="ml-2 font-display-regular text-xs font-normal text-gray-light uppercase text-center w-11">{copyString}</button>
                </CopyToClipboard>
              </motion.div>
            }
          </AnimatePresence>
        </div>
        <PDFDownloadLink
          document={<ArtWallPDF artWallProducts={artWallProducts} artWallScreenshot={artWallScreenshot} artWallShareableUrl={artWallShareableUrl} />}
          fileName="your-wall.pdf"
          className="h-12 flex justify-center items-center bg-black rounded-none font-display-bold text-xs font-normal text-white hover:text-white uppercase tracking-widest"
          style={{width: '227px'}}
        >
          {({ blob, url, loading, error, children }) =>
            loading && !artWallScreenshot ? 'Generating PDF' : 'Download PDF'
          }
        </PDFDownloadLink>
      </div>
    </motion.div>
  )
}

const mapStateToProps = (state) => {
  const props = {
    artWallProducts: state.artWall.artWallProducts,
    artWallScreenshot: state.artWall.artWallScreenshot,
    artWallScreenshotStatus: state.artWall.artWallScreenshotStatus,
    artWallCanvasRef: state.artWall.artWallCanvasRef,
    artWallCanvasWidth: state.artWall.artWallCanvasWidth,
    artWallCanvasHeight: state.artWall.artWallCanvasHeight,
    pathname: state.router.location.pathname,
    search: state.router.location.search,
    query: state.router.location.query,
    firstClick: state.artWall.firstClick,
    artWallShareableUrl: state.artWall.artWallShareableUrl,
    artWallProductsToBeShared: state.artWall.artWallProductsToBeShared
  }

  return props
}

const mapDispatchToProps = (dispatch) => {
  const actions = {
    setShowShareAndDownloadModal,
    setFirstClick,
    setArtWallShareableUrl,
    setArtWallProductsToBeShared,
    setArtWallScreenshot,
    setArtWallScreenshotStatus
  }

  const actionMap = {
    actions: bindActionCreators(actions, dispatch),
    dispatch
  }

  return actionMap
}

export default connect(mapStateToProps, mapDispatchToProps)(ArtWallShareModal)