import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AnimatePresence, motion } from "framer-motion";
import { isEmpty } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import useMeasure from "react-use-measure";
import { bindActionCreators } from "redux";
import { fadeAnimation } from "../animations";
import { twoDigits } from "../lib/parse";
import updateArtWallProducts from "../actions/artWall/updateArtWallProducts";
import setShowArtWallClearAllModal from "../actions/artWall/setShowArtWallClearAllModal";
import axios from "axios";
import setShowShareAndDownloadModal from "../actions/artWall/setShowShareAndDownloadModal";
import setArtWallShareableUrl from "../actions/artWall/setArtWallShareableUrl";
import { toPng } from "html-to-image";
import setArtWallScreenshot from "../actions/artWall/setArtWallScreenshot";
import setArtWallScreenshotStatus from "../actions/artWall/setArtWallScreenshotStatus";
import * as Tooltip from "@radix-ui/react-tooltip";
import { warpFragmentRefresh } from "../modules/ajaxAddToCart";
import { GA4AddToCart } from "../lib/tracking";
import { nanoid } from "nanoid/non-secure";

const ArtWallCartComponent = ({
  artWallProducts,
  actions,
  artWallCanvasRef,
}) => {
  const [expandArtWallCart, setExpandArtWallCart] = useState(false);
  const [artWallCartHeight, setArtWallCartHeight] = useState(false);
  const [artWallCartHeaderHeight, setArtWallCartHeaderHeight] = useState(false);
  const [artWallCartProductsHeight, setArtWallCartProductsHeight] =
    useState(false);
  const [subtotal, setSubtotal] = useState(0);
  const [frameSubtotal, setFrameSubtotal] = useState(0);
  const [buyArtWallUrl, setArtWallBuyUrl] = useState([]);
  const [isOn, setIsOn] = useState(true);
  const [arrays, setArrays] = useState([]);
  const [showBottomMenu, setShowBottomMenu] = useState(false);
  const [buttonText, setButtonText] = useState("Add to bag");
  const [checkoutButtonText, setCheckoutButtonText] = useState(
    "Proceed to checkout"
  );
  const [goToBag, setGoToBag] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [hasAddedToBag, setHasAddedToBag] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const [artWallCartHeaderRef, artWallCartHeaderRect] = useMeasure();
  const [artWallCartRef, artWallCartRect] = useMeasure();
  const [artWallCartProductsRef, artWallCartProductsRect] = useMeasure();

  useEffect(() => {
    // console.log('artWallProducts', artWallProducts);
    // console.log('artWallCartHeaderRect', artWallCartHeaderRect);
    // console.log('artWallCartRect', artWallCartRect);
    // console.log('artWallCartProductsRect', artWallCartProductsRect);
    setArtWallCartHeaderHeight(artWallCartHeaderRect.height);
    setArtWallCartHeight(artWallCartRect.height);
    setArtWallCartProductsHeight(artWallCartProductsRect.height);
  }, [
    artWallProducts,
    artWallCartHeaderRef,
    artWallCartRef,
    artWallCartProductsRef,
  ]);

  useEffect(() => {
    if (isEmpty(artWallProducts) && expandArtWallCart) {
      setExpandArtWallCart(false);
    }
  }, [artWallProducts]);

  useEffect(() => {
    const includesPricesArray = artWallProducts?.map(
      ({ id, price, selectedVariationId, selectedVariationPrice }) => ({
        id: selectedVariationId ? selectedVariationId : id,
        price: selectedVariationPrice
          ? parseInt(selectedVariationPrice)
          : parseInt(price),
      })
    );

    const includesSum = includesPricesArray?.reduce(
      (sum, { price }) => sum + parseInt(price),
      0
    );

    setSubtotal(includesSum);

    // console.log('artWallProducts', artWallProducts);
    const hasFrames = artWallProducts?.some(({ categories }) =>
      categories.some((category) => category.slug === "frames")
    );

    if (hasFrames) {
      const excludesPricesArray = artWallProducts
        ?.filter(
          ({ categories }) =>
            !categories.map((category) => category.slug).includes("frames")
        )
        .map(({ id, price, selectedVariationId, selectedVariationPrice }) => ({
          id: selectedVariationId ? selectedVariationId : id,
          price: selectedVariationPrice
            ? parseInt(selectedVariationPrice)
            : parseInt(price),
        }));

      const excludesSum = excludesPricesArray?.reduce(
        (sum, { price }) => sum + parseInt(price),
        0
      );

      setFrameSubtotal(excludesSum);
    } else {
      setFrameSubtotal(includesSum);
    }
  }, [artWallProducts]);

  useEffect(() => {
    const uuid = nanoid();
    const ArtWallIds = isOn
      ? artWallProducts?.map(({ id, selectedVariationId, categories }) =>
          !categories.some((category) => category.slug === "frames")
            ? `${selectedVariationId ? selectedVariationId : id}:1:${uuid}`
            : `${selectedVariationId ? selectedVariationId : id}:1:${uuid}`
        )
      : artWallProducts
          .filter(
            ({ categories }) =>
              !categories.some((category) => category.slug === "frames")
          )
          .map(
            ({ id, selectedVariationId }) =>
              `${selectedVariationId ? selectedVariationId : id}:1:${uuid}`
          );

    console.log("the art wall ids", ArtWallIds);

    setArtWallBuyUrl(ArtWallIds);
  }, [artWallProducts, isOn]);

  useEffect(() => {
    if (!isEmpty(artWallProducts)) {
      const prints = artWallProducts?.filter(
        ({ categories }) =>
          !categories.map((category) => category.slug).includes("frames")
      );
      const frames = artWallProducts?.filter(({ categories }) =>
        categories.map((category) => category.slug).includes("frames")
      );
      // console.log('prints', prints)
      // console.log('frames', frames)
      const combined = prints.map((print) =>
        [print, frames.find((frame) => print.uuid === frame.belongsTo)].filter(
          (item) => item !== undefined
        )
      );
      // console.log('combined', combined)

      setArrays(combined);
      setButtonText("Add to bag");
      setShowBottomMenu(false);
      setGoToBag(false);
    }
  }, [artWallProducts]);

  // console.log(artWallCartHeight);

  const artWalllAddToBagTracking = () => {
    if (!window.dataLayer) {
      return null;
    }

    const frameItems = arrays
      .filter((data) => {
        const item = data[0];
        return item.selectedFrame && !isEmpty(item.selectedFrame);
      })
      .map((data) => {
        const item = data[0]?.selectedFrame;
        const category = "Frame";
        const size = item.type === "variable" ? item.selectedSize?.name : "";
        return {
          name: item.post_title,
          id: item.id,
          value: item.price,
          listName: "Art Wall Designer",
          affiliation: "Art Wall Designer",
          quantity: 1,
          currency: "EUR",
          artist: "",
          size,
          category,
        };
      });

    const posterItems = arrays.map((data) => {
      const item = data[0];
      const categorySlugs = item.categories.map((cat) => cat.slug);
      const category = categorySlugs.includes("frames") ? "Frame" : "Poster";
      const size = item.type === "variable" ? item.selectedSize?.name : "";
      return {
        name: item.post_title,
        id: item.id,
        value: item.price,
        listName: "Art Wall Designer",
        affiliation: "Art Wall Designer",
        quantity: 1,
        currency: "EUR",
        artist: item?.artist?.[0]?.name,
        size,
        category,
      };
    });

    GA4AddToCart({ items: [...posterItems, ...frameItems] });
  };

  const beforeAddToBag = async () => {
    console.log("buyArtWallUrl", buyArtWallUrl);
    console.log("test");
    setButtonText("Adding");
    try {
      console.log("run AXISO for bag");
      // await axios.get(window.location.origin, {
      //   params: {
      //     ['add-to-cart']: encodeURIComponent(buyArtWallUrl.join(','))
      //   }
      // })
      const params = new URLSearchParams();
      params.append("addToCart", encodeURIComponent(buyArtWallUrl.join(",")));
      params.append("tpcSource", "awd");
      await axios({
        method: "post",
        url: window.location.origin,
        data: params,
      });
      // await axios.post(window.location.origin, {
      //   ['add-to-cart']: encodeURIComponent(buyArtWallUrl.join(','))
      // })
      setButtonText("Art wall added to bag");
      setShowBottomMenu(true);

      setTimeout(() => {
        setButtonText("View bag");
        jQuery.ajax(warpFragmentRefresh);
        setGoToBag(true);
        setShowSuccessModal(true);
        setHasAddedToBag(true);
        setTimeout(() => {
          setShowSuccessModal(false);
        }, 10000);
        // add TRY CATCH GTM tracking
        try {
          artWalllAddToBagTracking();
        } catch (e) {
          console.error(e);
        }
      }, 2500);
    } catch (error) {
      console.log("error", error);
    }
  };

  const addToBag = () => {
    console.log("trigger add to bag");
    goToBag
      ? (window.location.href = `${window.location.origin}/cart`)
      : beforeAddToBag();
  };

  const goToCheckout = () => {
    if (count < 1 && !hasAddedToBag) return;

    window.location.href = `${window.location.origin}/checkout`;
    // setCheckoutButtonText('Adding')
    // try {
    //   await axios.get(window.location.origin, {
    //     params: {
    //       ['add-to-cart']: encodeURIComponent(buyArtWallUrl.join(','))
    //     }
    //   })
    //   // await axios.post(window.location.origin, {
    //   //   ['add-to-cart']: encodeURIComponent(buyArtWallUrl.join(','))
    //   // })

    //   setCheckoutButtonText('Art wall added to bag')

    //   setTimeout(() => {
    //     setCheckoutButtonText('Going to checkout')

    //     setTimeout(() => {
    //       window.location.href = `${window.location.origin}/checkout`
    //     }, 500)
    //   }, 2500)
    // } catch (error) {
    //   console.log('error', error)
    // }
  };

  const clearAllHandler = () => {
    actions.setShowArtWallClearAllModal(true);
  };

  const handleShareAndDownload = useCallback(() => {
    actions.setShowShareAndDownloadModal(true);

    if (artWallCanvasRef.current === null) return;

    const getSharableUrlAndScreenshot = async () => {
      const baseUrl = window.location.origin;
      const urlResp = await axios.post(
        `${baseUrl}/wp-json/tpc/v1/share-art-wall`,
        {
          art_wall_value: artWallProducts,
        }
      );

      const awdUrl = window.location.href;
      const constructedUrl = `${awdUrl}?h=${urlResp.data}`;
      actions.setArtWallShareableUrl(constructedUrl);

      const screenshotResp = await axios.post(
        `https://share.theposterclub.com`,
        {
          url: constructedUrl,
        }
      );

      actions.setArtWallScreenshotStatus("done");

      actions.setArtWallScreenshot(screenshotResp.data.file);
    };

    getSharableUrlAndScreenshot();

    // const width = artWallCanvasRef.current.offsetWidth
    // const height = artWallCanvasRef.current.offsetHeight

    // toPng(artWallCanvasRef.current, { cacheBust: true, canvasWidth: width, canvasHeight: height })
    //   .then((dataUrl) => {
    //     actions.setArtWallScreenshot(dataUrl)
    //   })
    //   .catch((err) => {
    //     console.log(err)
    //   })
  }, [artWallCanvasRef, artWallProducts]);

  return (
    <>
      <motion.div
        ref={artWallCartRef}
        className="absolute top-full left-0 w-full z-50"
        animate={{
          y: expandArtWallCart
            ? artWallCartHeight >= 500
              ? -500
              : artWallCartHeight * -1
            : -artWallCartHeaderHeight,
        }}
        transition={{ type: "tween" }}
      >
        <div
          ref={artWallCartHeaderRef}
          className={`flex items-center justify-between h-15 bg-tpc-light px-8 border-t border-b border-solid border-gray w-full ${
            !isEmpty(artWallProducts) ? "cursor-pointer" : "cursor-default"
          }`}
          {...(!isEmpty(artWallProducts) && {
            onClick: () => setExpandArtWallCart(!expandArtWallCart),
          })}
        >
          <AnimatePresence>
            {!expandArtWallCart && (
              <motion.div
                className="flex items-baseline"
                initial="exit"
                animate="animate"
                exit="initial"
                variants={fadeAnimation}
              >
                <p className="font-body-regular text-black text-sm font-light mb-0">{`Subtotal € ${twoDigits(
                  parseInt(subtotal)
                )}`}</p>
              </motion.div>
            )}
          </AnimatePresence>
          <AnimatePresence>
            {!isEmpty(artWallProducts) && (
              <motion.div
                className="ml-auto"
                initial="exit"
                animate="animate"
                exit="initial"
                variants={fadeAnimation}
              >
                {/* <button className="font-display-regular text-sm text-black font-normal uppercase tracking-wide">{expandArtWallCart ? 'Hide Your Products' : 'View Your Products'} <FontAwesomeIcon icon={['fal', expandArtWallCart ? 'chevron-up' : 'chevron-down']} className="ml-3" /></button> */}
                <button className="font-display-regular text-sm text-black font-normal uppercase tracking-wide">
                  {expandArtWallCart ? "Close" : "Shop this art wall"}{" "}
                  <FontAwesomeIcon
                    icon={[
                      "fal",
                      expandArtWallCart ? "chevron-up" : "chevron-down",
                    ]}
                    className="ml-3"
                  />
                </button>
              </motion.div>
            )}
          </AnimatePresence>
        </div>

        {!isEmpty(artWallProducts) && (
          // <div className="flex" style={{height: artWallCartHeight >= 500 ? 'calc(500px - 60px)' : 'auto'}}>
          <div className="flex">
            <div
              className="flex flex-col bg-white"
              style={{
                width: "70%",
                height:
                  artWallCartHeight <= artWallCartProductsHeight + 60 + 55 &&
                  artWallCartHeight >= 500
                    ? "calc(500px - 60px)"
                    : "auto",
              }}
            >
              <div className="flex border-b border-solid border-gray bg-awd-dark items-center py-5 px-8">
                <p
                  className="font-display-regular text-sm text-black uppercase font-normal tracking-wide leading-none mb-0"
                  style={{ flex: 2 }}
                >
                  Your products
                </p>
                <p
                  className="font-display-regular text-sm text-black uppercase font-normal tracking-wide leading-none mb-0"
                  style={{ flex: 1 }}
                >
                  Size
                </p>
                <p
                  className="font-display-regular text-sm text-black uppercase font-normal tracking-wide leading-none mb-0"
                  style={{ flex: 1.5 }}
                >
                  Type
                </p>
                <p
                  className="font-display-regular text-sm text-black uppercase font-normal tracking-wide leading-none mb-0 text-right"
                  style={{ flex: 0.5 }}
                >
                  Price
                </p>
              </div>
              <div
                ref={artWallCartProductsRef}
                className="bg-white overflow-y-auto"
              >
                {/* <AnimatePresence>
                  {artWallProducts?.map(({post_title, artist, selectedSize, price, selectedVariationPrice, categories, color, uuid}) =>
                    <motion.div
                      key={uuid}
                      className="border-b border-solid border-gray"
                      initial="initial"
                      animate="animate"
                      exit="exit"
                      variants={fadeAnimation}
                    >
                      <div className="flex items-center py-3 px-8 transition-opacity duration-200 ease-in-out">
                        <div style={{flex: 2}}>
                          <p className="font-body-regular text-sm text-black font-normal leading-none mb-0">{post_title}</p>
                          <div className="flex mt-2">
                            {categories.find(category => category.slug === 'frames')
                              ? <p className="font-body-regular text-xs text-gray-light font-normal leading-none mr-4 mb-0">{color[0]?.name}</p>
                              : (
                                !isEmpty(artist)
                                  ? (artist.map((a, index) =>
                                    <p key={index} className="font-body-regular text-xs text-gray-light font-normal leading-none mr-4 mb-0">{a.name}</p>
                                  ))
                                  : <p className="font-body-regular text-xs text-gray-light font-normal leading-none mr-4 mb-0">Unknown Artist</p>
                              )
                            }
                          </div>
                        </div>
                        <p className="font-body-regular text-xs text-gray-light font-normal leading-none mb-0" style={{flex: 1}}>{selectedSize.name}</p>
                        <p className="font-body-regular text-xs text-gray-light font-normal leading-none mb-0" style={{flex: 1.5}}>{categories.find(category => category.slug === 'frames') ? 'Frames' : 'Art Print'}</p>
                        <p className="font-body-regular text-sm text-black font-normal leading-none mb-0 text-right " style={{flex: 0.5}}>{`€ ${selectedVariationPrice ? twoDigits(parseInt(selectedVariationPrice)) : twoDigits(parseInt(price))}`}</p>
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence> */}
                {arrays.map((array, index) => (
                  <div
                    key={index}
                    className="border-b border-solid border-gray py-3 px-8 relative"
                  >
                    {array.map(
                      ({
                        post_title,
                        artist,
                        selectedSize,
                        price,
                        selectedVariationPrice,
                        categories,
                        color,
                        uuid,
                      }) => (
                        <div key={uuid}>
                          <div
                            className={`flex items-center ${
                              categories.find(
                                (category) => category.slug === "frames"
                              )
                                ? "mt-5"
                                : "mt-0"
                            } ${
                              categories.find(
                                (category) => category.slug === "frames"
                              ) && array.length === 2
                                ? "border-l border-solid border-black pl-3"
                                : "border-none pl-0"
                            }`}
                          >
                            <div
                              style={{ flex: 2 }}
                              className={`${
                                categories.find(
                                  (category) => category.slug === "frames"
                                ) && array.length === 2
                                  ? "-mr-3"
                                  : "mr-0"
                              }`}
                            >
                              <p className="font-body-regular text-sm text-black font-normal leading-none mb-0">
                                {post_title}
                              </p>
                              <div className="flex mt-2">
                                {categories.find(
                                  (category) => category.slug === "frames"
                                ) ? (
                                  <p className="font-body-regular text-xs text-gray-light font-normal leading-none mr-4 mb-0">
                                    {color[0]?.name}
                                  </p>
                                ) : !isEmpty(artist) ? (
                                  artist.map((a, index) => (
                                    <p
                                      key={index}
                                      className="font-body-regular text-xs text-gray-light font-normal leading-none mr-4 mb-0"
                                    >
                                      {a.name}
                                    </p>
                                  ))
                                ) : (
                                  <p className="font-body-regular text-xs text-gray-light font-normal leading-none mr-4 mb-0">
                                    Unknown Artist
                                  </p>
                                )}
                              </div>
                            </div>
                            <p
                              className="font-body-regular text-xs text-gray-light font-normal leading-none mb-0"
                              style={{ flex: 1 }}
                            >
                              {selectedSize.name}
                            </p>
                            <p
                              className="font-body-regular text-xs text-gray-light font-normal leading-none mb-0"
                              style={{ flex: 1.5 }}
                            >
                              {categories.find(
                                (category) => category.slug === "frames"
                              )
                                ? "Frames"
                                : "Art Print"}
                            </p>
                            <p
                              className="font-body-regular text-sm text-black font-normal leading-none mb-0 text-right "
                              style={{ flex: 0.5 }}
                            >{`€ ${
                              selectedVariationPrice
                                ? twoDigits(parseInt(selectedVariationPrice))
                                : twoDigits(parseInt(price))
                            }`}</p>
                          </div>
                          {/* {array.length === 2 &&
                          <div className="absolute top-1/2 left-3 transform -translate-y-1/2 flex flex-col h-full py-4">
                            <div className="w-3 bg-gray" style={{height: '1px'}} />
                            <div className="flex-grow bg-gray" style={{width: '1px'}} />
                            <div className="w-3 bg-gray" style={{height: '1px'}} />
                          </div>
                        } */}
                        </div>
                      )
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div
              className="bg-tpc-light border-l border-solid border-gray p-8 flex items-center"
              style={{ width: "30%" }}
            >
              <div className="w-full">
                <p className="font-body-regular text-lg font-normal text-black leading-none text-center">
                  Subtotal:{" "}
                  {`€ ${twoDigits(parseInt(isOn ? subtotal : frameSubtotal))}`}
                </p>
                <div className="mt-5">
                  <button
                    className="bg-black text-tpc-light h-10 flex justify-center items-center w-full rounded-none font-display-bold font-normal text-sm hover:text-tpc-light cursor-pointer uppercase tracking-widest"
                    onClick={() => addToBag()}
                  >
                    {buttonText}
                  </button>
                  <Tooltip.Root
                    open={open}
                    onOpenChange={(value) => setShowTooltip(value)}
                  >
                    <Tooltip.Trigger
                      asChild
                      onMouseDown={(e) => e.preventDefault()}
                      onClick={() => setShowTooltip(true)}
                    >
                      <button
                        className={`border border-solid h-10 flex justify-center items-center w-full rounded-none font-display-bold font-normal text-sm cursor-pointer uppercase tracking-widest mt-3 ${
                          count < 1 && !hasAddedToBag
                            ? "text-gray border-gray"
                            : "text-black border-black"
                        }`}
                        onClick={() => goToCheckout()}
                      >
                        {checkoutButtonText}
                      </button>
                    </Tooltip.Trigger>
                    <AnimatePresence>
                      {showTooltip && count < 1 && !hasAddedToBag && (
                        <Tooltip.Content asChild side="left" sideOffset={12}>
                          <motion.p
                            initial="initial"
                            animate="animate"
                            exit="exit"
                            variants={fadeAnimation}
                            className="bg-black text-white font-display-regular font-normal text-xxs tracking-wider px-4 py-2 rounded-md"
                          >
                            Bag is empty
                          </motion.p>
                        </Tooltip.Content>
                      )}
                    </AnimatePresence>
                  </Tooltip.Root>
                  {/* <a href="/checkout" className="text-black border border-solid border-black h-10 flex justify-center items-center w-full rounded-none font-display-bold font-normal text-sm cursor-pointer uppercase tracking-widest mt-3">Proceed to checkout</a> */}
                  <AnimatePresence>
                    {showBottomMenu && (
                      <motion.div
                        className="mt-4 flex gap-4 justify-center"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ type: "tween" }}
                      >
                        <button
                          className="font-body-regular font-normal text-sm underline text-black"
                          onClick={() => handleShareAndDownload()}
                        >
                          Share
                        </button>
                        {/* <button className="font-body-regular font-normal text-sm underline text-black" onClick={() => handleShareAndDownload()}>Save a copy</button> */}
                        <button
                          className="font-body-regular font-normal text-sm underline text-black"
                          onClick={() => clearAllHandler()}
                        >
                          Clear wall
                        </button>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              </div>
            </div>
          </div>
        )}
      </motion.div>
      <AnimatePresence>
        {showSuccessModal && (
          <motion.div
            className="bg-bag border border-solid border-bag-dark fixed left-1/2 transform -translate-x-1/2 top-12 rounded z-50 py-2 px-18"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ type: "tween" }}
          >
            <p className="text-bag-darker mb-0 font-body-regular font-normal text-sm">
              Art wall added to{" "}
              <a href="/cart" className="underline text-black">
                bag
              </a>
            </p>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

const mapStateToProps = (state) => {
  const props = {
    artWallProducts: state.artWall.artWallProducts,
    artWallCanvasRef: state.artWall.artWallCanvasRef,
  };

  return props;
};

const mapDispatchToProps = (dispatch) => {
  const actions = {
    updateArtWallProducts,
    setShowArtWallClearAllModal,
    setShowShareAndDownloadModal,
    setArtWallScreenshot,
    setArtWallShareableUrl,
    setArtWallScreenshotStatus,
  };

  const actionMap = {
    actions: bindActionCreators(actions, dispatch),
    dispatch,
  };

  return actionMap;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ArtWallCartComponent);
