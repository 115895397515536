import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import setShowArtWallClearAllModal from "../actions/artWall/setShowArtWallClearAllModal"
import updateArtWallProducts from "../actions/artWall/updateArtWallProducts"

const ArtWallClearAllModalComponent = ({ actions }) => {
  const continueHandler = () => {
    actions.updateArtWallProducts({type: 'removeAll'})
    actions.setShowArtWallClearAllModal(false)
  }

  return (
    <div className="flex flex-col p-8 bg-tpc-light">
      <p className="mb-0 font-body-regular font-normal text-black">Do you want to clear this Art Wall?</p>
      <div className="flex gap-4 justify-center mt-5">
        <button
          className="flex justify-center items-center bg-black rounded-none font-display-bold text-xs font-normal text-white hover:text-white uppercase tracking-widest px-4"
          style={{paddingBottom: '7px', paddingTop: '9px'}}
          onClick={() => continueHandler()}
        >
          Continue
        </button>
        <button
          className="flex justify-center items-center border border-black rounded-none font-display-bold text-xs font-normal text-black hover:text-black uppercase tracking-widest px-4"
          style={{paddingBottom: '7px', paddingTop: '9px'}}
          onClick={() => actions.setShowArtWallClearAllModal(false)}
        >
          Cancel
        </button>
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  const actions = {
    setShowArtWallClearAllModal,
    updateArtWallProducts,
  }

  const actionMap = {
    actions: bindActionCreators(actions, dispatch),
    dispatch
  }

  return actionMap
}

export default connect(null, mapDispatchToProps)(ArtWallClearAllModalComponent)