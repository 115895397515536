import setArtWallProducts from "./setArtWallProducts";

export default function ({ product, artWallProducts }) {
  return (dispatch) => {
    console.log("DEBUG updateArtWallProduct action", artWallProducts);
    // console.log('product', product);
    // const isShared = search.length > 0 ? true : false

    const updatedArtWallProducts = artWallProducts.map((artWallProduct) =>
      artWallProduct.uuid === product.uuid ? product : artWallProduct
    );
    // console.log('updatedArtWallProducts', updatedArtWallProducts);
    // localStorage.setItem('artWallProducts', JSON.stringify(updatedArtWallProducts))
    // if (!isShared) {
    //   localStorage.setItem('favouriteIds', JSON.stringify(favouritesUpdated))
    // }

    return dispatch(setArtWallProducts(updatedArtWallProducts));
  };
}
