import { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ArtWallCanvas from "./ArtWallCanvas";
import ArtWallCartComponent from "./ArtWallCartComponent";
import ArtWallHeaderComponent from "./ArtWallHeaderComponent";
import ArtWallProductSidebarComponent from "./ArtWallProductSidebarComponent";
import ArtWallProductsSidebarComponent from "./ArtWallProductsSidebarComponent";
import setActiveArtWallBackground from "../actions/artWall/setActiveArtWallBackground";
import setFrames from "../actions/artWall/setFrames";
import updateArtWallProducts from "../actions/artWall/updateArtWallProducts";
import ArtWallEditRoomModalComponent from "./ArtWallEditRoomModalComponent";
import { AnimatePresence, motion } from "framer-motion";
import { fadeAnimation } from "../animations";
import ArtWallShareModal from "./ArtWallShareModal";
import axios from "axios";
import ArtWallSpinner from "./ArtWallSpinner";
import { isEmpty } from "lodash";
import ArtWallClearAllModalComponent from "./ArtWallClearAllModalComponent";
import ArtWallCloseModalComponent from "./ArtWallCloseModalComponent";
import setArtWallScreenshotStatus from "../actions/artWall/setArtWallScreenshotStatus";
import { showShareModalAtom } from "../jotai";
import { useAtom } from "jotai";

const ArtWallComponent = ({
  artWallProducts,
  showEditRoomModal,
  showShareAndDownloadModal,
  actions,
  search,
  artWallScreenshot,
  showArtWallClearAllModal,
  showArtWallCloseModal,
  artWallScreenshotStatus,
}) => {
  const [showShareModal, setShowShareModal] = useAtom(showShareModalAtom);

  //To-do: there are still some quirks with sharing
  useEffect(() => {
    console.log("DEBUG on mount fetch call or get from localstorage");
    if (search) {
      const baseUrl = window.location.origin;

      axios
        .get(`${baseUrl}/wp-json/tpc/v1/get-art-wall`, {
          params: {
            h: search.substring(3),
          },
        })
        .then((response) => {
          actions.updateArtWallProducts({
            artWallProducts: response.data,
            type: "set",
            initial: true,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      // console.log('yolo');
      const localArtWalProducts = localStorage.getItem("artWallProducts")
        ? JSON.parse(localStorage.getItem("artWallProducts"))
        : [];

      if (isEmpty(artWallProducts) && !isEmpty(localArtWalProducts)) {
        // console.log('molo');
        actions.updateArtWallProducts({
          artWallProducts: localArtWalProducts,
          type: "set",
          initial: true,
        });
      }
    }
  }, []);

  useEffect(() => {
    actions.setActiveArtWallBackground(artWallWalls[0]);
  }, []);

  useEffect(() => {
    const fetchFrames = async () => {
      const result = await axios(
        `/wp-json/tpc/v1/products?page=1&limit=100&category=frames`
      );
      const frames = result.data.products;
      const onlyFramesWithFrameTypeframes = frames.filter(
        (frame) => !isEmpty(frame.frameType)
      );
      console.log(
        "onlyFramesWithFrameTypeframes",
        onlyFramesWithFrameTypeframes
      );
      actions.setFrames(onlyFramesWithFrameTypeframes);
    };

    fetchFrames();
  }, []);

  useEffect(() => {
    if (!isEmpty(artWallProducts)) {
      actions.setArtWallScreenshotStatus("generating");
    }
    console.log("artWallProducts has been updated", artWallProducts);
  }, [artWallProducts]);

  console.log("showShareAndDownloadModal", showShareAndDownloadModal);
  console.log("artWallScreenshotStatus", artWallScreenshotStatus);
  console.log("artWallScreenshot", artWallScreenshot);

  return (
    <div className="flex flex-col h-screen w-screen relative overflow-hidden">
      <ArtWallHeaderComponent />
      <div className="flex relative" style={{ height: "calc(100% - 120px)" }}>
        <ArtWallProductsSidebarComponent />
        <ArtWallProductSidebarComponent />
        <ArtWallCanvas />
      </div>
      <ArtWallCartComponent />
      <AnimatePresence>
        {showArtWallClearAllModal && (
          <motion.div
            className="h-screen w-screen bg-black bg-opacity-20 absolute top-0 left-0 z-999 flex justify-center items-center"
            initial="initial"
            animate="animate"
            exit="exit"
            variants={fadeAnimation}
          >
            <ArtWallClearAllModalComponent />
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {showArtWallCloseModal && (
          <motion.div
            className="h-screen w-screen bg-black bg-opacity-20 absolute top-0 left-0 z-999 flex justify-center items-center"
            initial="initial"
            animate="animate"
            exit="exit"
            variants={fadeAnimation}
          >
            <ArtWallCloseModalComponent />
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {showEditRoomModal && (
          <motion.div
            className="h-screen w-screen bg-black bg-opacity-20 absolute top-0 left-0 z-999 flex justify-center items-center"
            initial="initial"
            animate="animate"
            exit="exit"
            variants={fadeAnimation}
          >
            <ArtWallEditRoomModalComponent />
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {showShareModal && (
          <motion.div
            key="overlay"
            className="h-screen w-screen bg-black bg-opacity-20 absolute top-0 left-0 z-999 flex justify-center items-center"
            initial="hide"
            animate="show"
            exit="hide"
            variants={{
              hide: {
                opacity: 0,
              },
              show: {
                opacity: 1,
              },
            }}
          >
            <motion.div
              key="modal"
              variants={{
                hide: {
                  opacity: 0,
                },
                show: {
                  opacity: 1,
                },
              }}
            >
              <ArtWallShareModal />
            </motion.div>
            {/* <AnimatePresence exitBeforeEnter>
              {((!artWallScreenshot && artWallScreenshotStatus === 'generating') || (artWallScreenshot && artWallScreenshotStatus !== 'done')) &&
                <motion.div
                  key="spinner"
                  initial="initial"
                  animate="animate"
                  exit="exit"
                  variants={fadeAnimation}
                >
                  <div>
                    <p className="font-body-regular font-normal text-lg text-tpc-light tracking-wide mb-4">Hang on. Generating shareable preview</p>
                    <ArtWallSpinner hexColor="#ffffff" />
                  </div>
                </motion.div>
              }
              {artWallScreenshot && artWallScreenshotStatus === 'done' &&
                <motion.div
                  key="shareAndDownloadModal"
                  initial="initial"
                  animate="animate"
                  exit="exit"
                  variants={fadeAnimation}
                >
                  <ArtWallShareAndDownloadModal />
                </motion.div>
              }
            </AnimatePresence> */}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const mapStateToProps = (state) => {
  const props = {
    artWallProducts: state.artWall.artWallProducts,
    showEditRoomModal: state.artWall.showEditRoomModal,
    showShareAndDownloadModal: state.artWall.showShareAndDownloadModal,
    search: state.router.location.search,
    artWallScreenshot: state.artWall.artWallScreenshot,
    artWallScreenshotStatus: state.artWall.artWallScreenshotStatus,
    showArtWallClearAllModal: state.artWall.showArtWallClearAllModal,
    showArtWallCloseModal: state.artWall.showArtWallCloseModal,
  };

  return props;
};

const mapDispatchToProps = (dispatch) => {
  const actions = {
    setActiveArtWallBackground,
    updateArtWallProducts,
    setFrames,
    setArtWallScreenshotStatus,
  };

  const actionMap = {
    actions: bindActionCreators(actions, dispatch),
    dispatch,
  };

  return actionMap;
};

export default connect(mapStateToProps, mapDispatchToProps)(ArtWallComponent);
