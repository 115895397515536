import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { has, isEmpty } from "lodash";
import { nanoid } from "nanoid/non-secure";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import setMinimizeArtWallProductSidebar from "./../actions/artWall/setMinimizeArtWallProductSidebar";
import setArtWallActiveProduct from "./../actions/artWall/setArtWallActiveProduct";
import updateArtWallProduct from "./../actions/artWall/updateArtWallProduct";
import updateArtWallProducts from "./../actions/artWall/updateArtWallProducts";
import hasFrame from "../utilities/hasFrame";
import * as Tooltip from "@radix-ui/react-tooltip";
import { fadeAnimation } from "../animations";
import getSortedProductSizes from "../utilities/getSortedProductSizes";

const ArtWallProductSidebarComponent = ({
  actions,
  artWallActiveProduct,
  minimizeArtWallProductSidebar,
  artWallProducts,
  frames,
  search,
}) => {
  const [activeSize, setActiveSize] = useState(null);
  const [activeFrame, setActiveFrame] = useState(null);
  const [availableFrames, setAvailableFrames] = useState([]);
  const [shouldAddDefaultFrame, setShouldAddDefaultFrame] = useState(true);
  const [showTooltip, setShowTooltip] = useState(false);

  const hasMultipleSizes = artWallActiveProduct.type === "variable";

  // console.log('artWallActiveProduct', artWallActiveProduct);

  const sizeChangeHandler = async (size) => {
    console.log("DEBUG sizeChangeHandler()");
    /** Only run if art print has multiple sizes */
    if (size && hasMultipleSizes) {
      /** Start - Find new size and update art print to new size */
      const variationMatch = artWallActiveProduct.variations.find(
        (variation) => variation.sizeSlug === size.slug
      );
      const { artWallActiveProduct: semiUpdatedArtWallActiveProduct } =
        await actions.setArtWallActiveProduct({
          ...artWallActiveProduct,
          selectedSize: size,
          selectedVariationId: variationMatch.id,
          selectedVariationPrice: parseInt(variationMatch.price),
        });

      await actions.updateArtWallProduct({
        product: semiUpdatedArtWallActiveProduct,
        artWallProducts,
      });
      /** End - Find new size and update art print to new size */

      /** Start - Set new size slug for selecting correct size swatch  */
      setActiveSize(size.slug);
      /** End - Set new size slug for selecting correct size swatch  */

      /** Start - Attempt to switch to correct size frame for updated art print  */
      console.log(
        "semiUpdatedArtWallActiveProduct",
        semiUpdatedArtWallActiveProduct
      );
      console.log(
        "hasFrame(semiUpdatedArtWallActiveProduct)",
        hasFrame(semiUpdatedArtWallActiveProduct)
      );
      console.log("frames", frames);
      if (hasFrame(semiUpdatedArtWallActiveProduct)) {
        const eligibleFrames = frames
          .map((frame) => {
            return frame.size.find(
              (s) =>
                s.slug ===
                semiUpdatedArtWallActiveProduct.selectedSize
                  .matchingFrameSizes[0].slug
            );
          })
          .filter((frame) => frame !== undefined);

        const hasSizeForSelectedFrame =
          hasFrame(semiUpdatedArtWallActiveProduct).size.find(
            (s) =>
              s.matchingFrameSizes?.[0]?.slug ===
              semiUpdatedArtWallActiveProduct?.selectedSize
                ?.matchingFrameSizes?.[0]?.slug
          ) !== undefined;

        console.log("hasSizeForSelectedFrame", hasSizeForSelectedFrame);
        console.log("eligibleFrames", eligibleFrames);

        if (isEmpty(eligibleFrames) || !hasSizeForSelectedFrame) {
          console.log("isEmpty");
          const { artWallActiveProduct: updatedArtWallActiveProduct } =
            await actions.setArtWallActiveProduct({
              ...semiUpdatedArtWallActiveProduct,
              selectedFrame: null,
            });

          const { artWallProducts: updatedArtWallProducts } =
            await actions.updateArtWallProduct({
              product: updatedArtWallActiveProduct,
              artWallProducts,
            });

          await actions.updateArtWallProducts({
            product: activeFrame,
            artWallProducts: updatedArtWallProducts,
            type: "remove",
          });

          setActiveFrame(null);
        } else {
          const uuid = nanoid();

          const frameId = semiUpdatedArtWallActiveProduct.selectedFrame.id;
          const frameType =
            semiUpdatedArtWallActiveProduct?.selectedFrame?.frameType?.[0]
              ?.name;

          const matchingFrames = availableFrames.filter(
            ({ type }) => type === frameType
          )[0]?.frames;
          const matchingFrameInNewSize = matchingFrames.find(
            ({ id }) => id === frameId
          );

          const { artWallActiveProduct: updatedArtWallActiveProduct } =
            await actions.setArtWallActiveProduct({
              ...semiUpdatedArtWallActiveProduct,
              selectedFrame: {
                ...matchingFrameInNewSize,
                uuid: uuid,
                belongsTo: semiUpdatedArtWallActiveProduct.uuid,
              },
            });

          const { artWallProducts: updatedArtWallProducts } =
            await actions.updateArtWallProduct({
              product: {
                ...updatedArtWallActiveProduct,
                selectedFrame: {
                  ...matchingFrameInNewSize,
                  uuid: uuid,
                  belongsTo: updatedArtWallActiveProduct.uuid,
                },
              },
              artWallProducts,
            });

          await actions.updateArtWallProducts({
            product: {
              ...matchingFrameInNewSize,
              uuid: uuid,
              belongsTo: updatedArtWallActiveProduct.uuid,
            },
            existing: artWallActiveProduct.selectedFrame,
            artWallProducts: updatedArtWallProducts,
            type: "switchFrame",
          });

          setActiveFrame({
            ...matchingFrameInNewSize,
            uuid: uuid,
            belongsTo: updatedArtWallActiveProduct,
          });
        }
      }
      /** End - Attempt to switch to correct size frame for updated art print  */
    }
  };

  //unmount: remove active frame and active size
  useEffect(() => {
    return () => {
      setActiveFrame(null);
      setActiveSize(null);
    };
  }, []);

  //onupdate active poster: set correct active poster or remove it
  //onupdate active poster: set current active poster size locally
  useEffect(() => {
    // console.log('artWallActiveProduct didUpdate', artWallActiveProduct);

    if (hasFrame(artWallActiveProduct)) {
      setActiveFrame(artWallActiveProduct.selectedFrame);
    } else {
      setActiveFrame(null);
    }

    if (!isEmpty(artWallActiveProduct)) {
      setActiveSize(artWallActiveProduct.selectedSize.slug);
    }
  }, [artWallActiveProduct]);

  //onupdate art wall products: minimize product sidebar if the last art wall product has been removed
  useEffect(() => {
    // console.log('artWallProducts did update', artWallProducts);
    // console.log('availableFrames', availableFrames);

    if (isEmpty(artWallProducts) && !minimizeArtWallProductSidebar) {
      actions.setMinimizeArtWallProductSidebar(true);
    }
  }, [artWallProducts]);

  //sort frames into type groups
  useEffect(() => {
    // console.log('FIND AVAILABLE FRAMES');
    if (
      !isEmpty(artWallActiveProduct) &&
      !isEmpty(frames) &&
      has(artWallActiveProduct, "selectedSize.matchingFrameSizes")
    ) {
      console.log("frames", frames);
      const supportedFramesForArtPrint = frames.filter((frame, index) => {
        console.log("YOLO frame", frame);
        const frameMatchForActiveArtPrintSize = frame.variations.find(
          (variation) =>
            variation.sizeSlug ===
            artWallActiveProduct?.selectedSize?.matchingFrameSizes?.[0]?.slug
        );

        console.log(
          "frameMatchForActiveArtPrintSize",
          frameMatchForActiveArtPrintSize
        );
        frame.selectedSize =
          artWallActiveProduct?.selectedSize?.matchingFrameSizes?.[0];
        frame.selectedVariationId = frameMatchForActiveArtPrintSize?.id;
        frame.selectedVariationPrice = parseInt(
          frameMatchForActiveArtPrintSize?.price
        );
        frame.selectedVariationImage =
          frameMatchForActiveArtPrintSize?.frameImageForArtWallDesigner;

        return frame.size.find(
          (s) =>
            s.slug ===
            artWallActiveProduct?.selectedSize?.matchingFrameSizes?.[0]?.slug
        );
      });
      // console.log('supportedFramesForArtPrint', supportedFramesForArtPrint);

      const framesSortedByType = Object.values(
        supportedFramesForArtPrint.reduce((acc, frame) => {
          const type = frame.frameType?.[0]?.name;

          if (!acc[type]) {
            acc[type] = {
              type: type,
              frames: [frame],
            };
          } else {
            acc[type].frames.push(frame);
          }

          return acc;
        }, {})
      );
      // console.log('framesSortedByType', framesSortedByType);

      setAvailableFrames(framesSortedByType);
    }
  }, [artWallActiveProduct, frames]);

  const frameChangeHandler = async ({ frame, noFrame }) => {
    // console.log('frame', frame);

    if (artWallActiveProduct.selectedFrame === null) {
      console.log("ADD FRAME");

      const uuid = nanoid();

      const updatedArtWallActiveProduct = await actions.setArtWallActiveProduct(
        {
          ...artWallActiveProduct,
          selectedFrame: { ...frame, uuid: uuid },
        }
      );

      const updatedArtWallProducts = await actions.updateArtWallProduct({
        product: {
          ...updatedArtWallActiveProduct.artWallActiveProduct,
          selectedFrame: { ...frame, uuid: uuid },
        },
        artWallProducts,
      });

      await actions.updateArtWallProducts({
        product: {
          ...frame,
          uuid: uuid,
          belongsTo: updatedArtWallActiveProduct.artWallActiveProduct.uuid,
        },
        artWallProducts: updatedArtWallProducts.artWallProducts,
        type: "add",
      });

      setActiveFrame({
        ...frame,
        uuid: uuid,
        belongsTo: artWallActiveProduct.uuid,
      });
    } else {
      if (noFrame && activeFrame !== null) {
        console.log("REMOVE FRAME");

        const updatedArtWallActiveProduct =
          await actions.setArtWallActiveProduct({
            ...artWallActiveProduct,
            selectedFrame: null,
          });
        // console.log('updatedArtWallActiveProduct', updatedArtWallActiveProduct.artWallActiveProduct)

        const updatedArtWallProducts = await actions.updateArtWallProduct({
          product: updatedArtWallActiveProduct.artWallActiveProduct,
          artWallProducts,
        });
        // console.log('updatedArtWallProducts', updatedArtWallProducts.artWallProducts)

        await actions.updateArtWallProducts({
          product: activeFrame,
          artWallProducts: updatedArtWallProducts.artWallProducts,
          type: "remove",
        });

        setActiveFrame(null);
      } else {
        if (
          hasFrame(artWallActiveProduct) &&
          !isEmpty(artWallActiveProduct.selectedFrame)
        ) {
          console.log("SWITCH FRAME");
          // console.log('artWallActiveProduct', artWallActiveProduct);
          const uuid = nanoid();

          const updatedArtWallActiveProduct =
            await actions.setArtWallActiveProduct({
              ...artWallActiveProduct,
              selectedFrame: {
                ...frame,
                uuid: uuid,
                belongsTo: artWallActiveProduct.uuid,
              },
            });
          // console.log('updatedArtWallActiveProduct', updatedArtWallActiveProduct);

          const updatedArtWallProducts = await actions.updateArtWallProduct({
            product: {
              ...updatedArtWallActiveProduct.artWallActiveProduct,
              selectedFrame: {
                ...frame,
                uuid: uuid,
                belongsTo:
                  updatedArtWallActiveProduct.artWallActiveProduct.uuid,
              },
            },
            artWallProducts,
          });
          // console.log('updatedArtWallProducts', updatedArtWallProducts);

          await actions.updateArtWallProducts({
            product: {
              ...frame,
              uuid: uuid,
              belongsTo: updatedArtWallActiveProduct.artWallActiveProduct.uuid,
            },
            existing: artWallActiveProduct.selectedFrame,
            artWallProducts: updatedArtWallProducts.artWallProducts,
            type: "switchFrame",
          });

          setActiveFrame({
            ...frame,
            uuid: uuid,
            belongsTo: artWallActiveProduct.uuid,
          });
        }
      }
    }
  };

  console.log("artWallActiveProduct", artWallActiveProduct);

  useEffect(() => {
    const debugId = nanoid();
    console.log("DEBUG useffect on update 1", artWallProducts, debugId);
    console.log("DEBUG artWallActiveProduct", artWallActiveProduct, debugId);
    if (!isEmpty(artWallActiveProduct)) {
      console.log("DEBUG useffect on update 2", debugId);
      // console.log('artWallActiveProduct', artWallActiveProduct);
      const eligibleFrames = frames
        .map((frame) => {
          return frame.size.find(
            (s) =>
              s.slug ===
              artWallActiveProduct?.selectedSize?.matchingFrameSizes?.[0]?.slug
          );
        })
        .filter((frame) => frame !== undefined);
      // console.log('shouldAddDefaultFrame', shouldAddDefaultFrame);
      console.log("DEBUG eligibleFrames", eligibleFrames, debugId);
      if (
        !isEmpty(eligibleFrames) &&
        artWallActiveProduct.justAdded &&
        !isEmpty(availableFrames) &&
        !isEmpty(artWallActiveProduct) &&
        artWallActiveProduct.selectedFrame === null
      ) {
        const oakGroup = availableFrames.find((group) => group.type === "Oak");
        // console.log('oakGroup', oakGroup);

        if (oakGroup) {
          const oakFrame = oakGroup?.frames.find(
            (frame) => frame.post_title === "Oak Frame"
          );
          // console.log('oakFrame', oakFrame);

          if (oakFrame) {
            const addOakFrame = async () => {
              // console.log('ADD OAK FRAME');

              const uuid = nanoid();

              console.log(
                "DEBUG : existing art wall prods before setting active",
                artWallProducts,
                debugId
              );
              const updatedArtWallActiveProduct =
                await actions.setArtWallActiveProduct({
                  ...artWallActiveProduct,
                  selectedFrame: { ...oakFrame, uuid: uuid },
                  justAdded: false,
                });

              console.log(
                "DEBUG : existing art wall prods before updating one prod",
                artWallProducts,
                debugId
              );
              const updatedArtWallProducts = await actions.updateArtWallProduct(
                {
                  product: {
                    ...updatedArtWallActiveProduct.artWallActiveProduct,
                    selectedFrame: { ...oakFrame, uuid: uuid },
                    justAdded: false,
                  },
                  artWallProducts,
                }
              );

              console.log(
                "THE updated art wall products and existing",
                updatedArtWallProducts,
                artWallProducts,
                debugId
              );

              await actions.updateArtWallProducts({
                product: {
                  ...oakFrame,
                  uuid: uuid,
                  belongsTo:
                    updatedArtWallActiveProduct.artWallActiveProduct.uuid,
                },
                artWallProducts: updatedArtWallProducts.artWallProducts,
                type: "add",
              });

              setActiveFrame({
                ...oakFrame,
                uuid: uuid,
                belongsTo: artWallActiveProduct.uuid,
              });
            };

            // addOakFrame();
          } else {
            setActiveFrame(null);
          }
        } else {
          setActiveFrame(null);
        }
      } else {
        console.log("DEBUG did not match conditional", debugId);
      }
    }
  }, [shouldAddDefaultFrame, availableFrames, artWallActiveProduct]);

  // console.log('availableFrames', availableFrames);

  // const FrameSwatch = ({ frame, index }) => {
  //   const [showTooltip, setShowTooltip] = useState(false)
  //   // console.log(frame);
  //   return (
  //     <Tooltip.Root open={open} onOpenChange={value => setShowTooltip(value)}>
  //       <Tooltip.Trigger asChild>
  //         <button
  //           key={`${frame.frameType[0]?.slug}-${index}`}
  //           className="w-6 h-6 rounded-full ml-4 relative"
  //           style={{backgroundColor: frame?.color[0]?.description}}
  //           onClick={() => frameChangeHandler({frame, noFrame: false})}
  //         >
  //           {activeFrame && activeFrame.selectedVariationId === frame.selectedVariationId && <motion.span className="block absolute border border-solid border-black rounded-full" style={{inset: '-5px'}} />}
  //         </button>
  //       </Tooltip.Trigger>
  //       <AnimatePresence>
  //         {showTooltip &&
  //           <Tooltip.Content sideOffset={8} asChild>
  //             <motion.p initial="initial" animate="animate" exit="exit" variants={fadeAnimation} className="bg-black text-white font-display-regular font-normal text-xxs tracking-wider px-4 py-2 rounded-md">{frame.post_title}</motion.p>
  //           </Tooltip.Content>
  //         }
  //       </AnimatePresence>
  //     </Tooltip.Root>
  //   )
  // }

  // console.log('activeSize', activeSize);
  // console.log('activeFrame', activeFrame);

  const determineNoteText = (size) => {
    switch (size) {
      case "100x100-cm":
        return "We do not offer matching frames for the 100×100 cm prints. Please consult your local framing shop for available options. We always recommend having x-large prints mounted before being framed to avoid the paper from warping or buckling.";

      case "100x140-cm":
      case "1000x1400-mm":
        return "We do not offer matching frames for the 100×140 cm prints. Please consult your local framing shop for available options. We always recommend having x-large prints mounted before being framed to avoid the paper from warping or buckling.";

      default:
        break;
    }
  };

  return (
    <AnimatePresence>
      {!minimizeArtWallProductSidebar && (
        <motion.div
          key="awpsc"
          className="absolute top-0 left-0 flex h-full z-20 pointer-events-none"
          initial={{ x: "-100%", opacity: 0 }}
          animate={{
            x: !minimizeArtWallProductSidebar ? 0 : "-100%",
            opacity: 1,
          }}
          exit={{ x: "-100%", opacity: 0 }}
          transition={{ type: "tween" }}
        >
          <div className="flex flex-col w-100 bg-tpc-darker pointer-events-auto">
            <div className="min-h-15 bg-tpc-light border-b border-solid border-gray flex">
              <div className="flex-grow flex flex-col justify-center pl-8 pr-4 pt-2 pb-3 border-r border-solid border-gray">
                <p className="font-body-regular text-xl font-normal text-black mb-0">
                  {artWallActiveProduct.post_title}
                </p>
                {!isEmpty(artWallActiveProduct) &&
                  artWallActiveProduct.artist.map((a, index) => (
                    <p
                      key={index}
                      className="font-body-regular text-xs font-normal text-gray-light mb-0"
                    >
                      {a.name}
                    </p>
                  ))}
                {isEmpty(artWallActiveProduct.artist) && (
                  <p className="font-body-regular text-xs font-normal text-gray-light mb-0">
                    Unknown Artist
                  </p>
                )}
              </div>
              <div
                className="flex justify-center items-center w-15 min-h-15 cursor-pointer"
                onClick={() => actions.setMinimizeArtWallProductSidebar(true)}
              >
                <FontAwesomeIcon icon={["fal", "times"]} className="block" />
              </div>
            </div>
            <div className="px-5">
              <div className="mt-8">
                <p className="font-body-regular text-sm text-black font-normal leading-none mb-0 tracking-wide">
                  {!isEmpty(artWallActiveProduct) &&
                  artWallActiveProduct.size.length === 1
                    ? "Size"
                    : "Sizes"}
                </p>
                <div className="flex flex-wrap">
                  {!isEmpty(artWallActiveProduct) &&
                    getSortedProductSizes(artWallActiveProduct).map(
                      (s, index) => (
                        <button
                          key={index}
                          className={classNames(
                            "font-body-regular text-xs text-gray-light mr-3 py-2 px-4 bg-tpc-light rounded-full mt-5",
                            {
                              "bg-gray":
                                activeSize && activeSize === s.slug
                                  ? true
                                  : false,
                            }
                          )}
                          {...(hasMultipleSizes && {
                            onClick: () => sizeChangeHandler(s),
                          })}
                        >
                          {s.name}
                        </button>
                      )
                    )}
                </div>
              </div>
              {(activeSize === "1000x1400-mm" ||
                activeSize === "100x140-cm" ||
                activeSize === "100x100-cm") && (
                <div className="mt-8">
                  <p className="font-body-regular text-sm text-black font-normal inline-block leading-none mb-0 tracking-wide">
                    Note
                  </p>
                  <p className="font-body-regular text-xs text-gray-light font-normal mt-1">
                    {determineNoteText(activeSize)}
                  </p>
                </div>
              )}
              {!isEmpty(availableFrames) ? (
                <div className="mt-8">
                  <p className="font-body-regular text-sm text-black font-normal inline-block leading-none mb-0 tracking-wide">
                    Frames
                  </p>
                  <div className="mt-5">
                    <div className="-mt-6">
                      {availableFrames.map((frameGroup, index) => (
                        <div key={index} className="pt-6">
                          <p className="font-display-regular text-xs text-gray-light font-normal uppercase mb-3 tracking-wide">
                            {frameGroup.type} frames
                          </p>
                          <div className="flex flex-wrap -ml-4 -mt-4 w-11/12">
                            {frameGroup.frames.map((frame, index) => (
                              // <FrameSwatch key={`${frame.frameType[0].slug}-${index}`} frame={frame} index={index} />
                              <Tooltip.Root
                                key={index}
                                open={open}
                                onOpenChange={(value) =>
                                  setShowTooltip({
                                    value: value,
                                    origin: `${frame?.frameType?.[0]?.slug}-${index}`,
                                  })
                                }
                              >
                                <Tooltip.Trigger asChild>
                                  <button
                                    key={`${frame?.frameType?.[0]?.slug}-${index}`}
                                    className="w-6 h-6 rounded-full ml-4 mt-4 relative"
                                    style={{
                                      backgroundColor:
                                        frame?.color?.[0]?.description,
                                    }}
                                    onClick={() =>
                                      frameChangeHandler({
                                        frame,
                                        noFrame: false,
                                      })
                                    }
                                  >
                                    {activeFrame &&
                                      activeFrame.selectedVariationId ===
                                        frame.selectedVariationId && (
                                        <motion.span
                                          className="block absolute border border-solid border-black rounded-full"
                                          style={{ inset: "-5px" }}
                                        />
                                      )}
                                  </button>
                                </Tooltip.Trigger>
                                <AnimatePresence>
                                  {showTooltip.value &&
                                    showTooltip.origin ===
                                      `${frame?.frameType?.[0]?.slug}-${index}` && (
                                      <Tooltip.Content sideOffset={8} asChild>
                                        <motion.p
                                          initial="initial"
                                          animate="animate"
                                          exit="exit"
                                          variants={fadeAnimation}
                                          className="bg-black text-white font-display-regular font-normal text-xxs tracking-wider px-4 py-2 rounded-md"
                                        >
                                          {frame.post_title}
                                        </motion.p>
                                      </Tooltip.Content>
                                    )}
                                </AnimatePresence>
                              </Tooltip.Root>
                            ))}
                          </div>
                        </div>
                      ))}
                      <div className="pt-6">
                        <p className="font-display-regular text-xs text-gray-light font-normal uppercase mb-3 tracking-wide">
                          No Frame
                        </p>
                        <div className="flex -ml-4">
                          <button
                            className="w-6 h-6 rounded-full ml-4 flex justify-center items-center bg-white relative"
                            onClick={() =>
                              frameChangeHandler({ noFrame: true })
                            }
                          >
                            {activeFrame === null && (
                              <motion.span
                                className="block absolute border border-solid border-black rounded-full"
                                style={{ inset: "-5px" }}
                              />
                            )}
                            <span
                              className="h-6 bg-gray transform -rotate-45"
                              style={{ width: "2px" }}
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="mt-8">
                  <p className="font-body-regular text-sm text-black font-normal inline-block leading-none mb-0 tracking-wide">
                    Frames
                  </p>
                  <div className="mt-5">
                    <div className="-mt-6">
                      <div className="pt-6">
                        <p className="font-display-regular text-xs text-gray-light font-normal uppercase mb-3 tracking-wide">
                          No Frame
                        </p>
                        <div className="flex -ml-4">
                          <button
                            className="w-6 h-6 rounded-full ml-4 flex justify-center items-center bg-white relative"
                            onClick={() =>
                              frameChangeHandler({ noFrame: true })
                            }
                          >
                            {activeFrame === null && (
                              <motion.span
                                className="block absolute border border-solid border-black rounded-full"
                                style={{ inset: "-5px" }}
                              />
                            )}
                            <span
                              className="h-6 bg-gray transform -rotate-45"
                              style={{ width: "2px" }}
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

const mapDispatchToProps = (dispatch) => {
  const actions = {
    setMinimizeArtWallProductSidebar,
    updateArtWallProduct,
    updateArtWallProducts,
    setArtWallActiveProduct,
  };

  const actionMap = {
    actions: bindActionCreators(actions, dispatch),
    dispatch,
  };

  return actionMap;
};

const mapStateToProps = (state) => {
  const props = {
    artWallActiveProduct: state.artWall.artWallActiveProduct,
    minimizeArtWallProductSidebar: state.artWall.minimizeArtWallProductSidebar,
    artWallProducts: state.artWall.artWallProducts,
    frames: state.artWall.frames,
    search: state.router.location.search,
  };

  return props;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ArtWallProductSidebarComponent);
