import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import axios from "axios";
import { bindActionCreators } from "redux";
import { useCallback, useEffect, useState } from "react";
import setArtWallScreenshot from "../actions/artWall/setArtWallScreenshot";
import setShowShareAndDownloadModal from "../actions/artWall/setShowShareAndDownloadModal";
import setShowArtWallCloseModal from "../actions/artWall/setShowArtWallCloseModal";
import setArtWallShareableUrl from "../actions/artWall/setArtWallShareableUrl";
import { isEmpty } from "lodash";
import classNames from "classnames";
import * as Tooltip from "@radix-ui/react-tooltip";
import { AnimatePresence, motion } from "framer-motion";
import { fadeAnimation } from "../animations";
import setArtWallScreenshotStatus from "../actions/artWall/setArtWallScreenshotStatus";
import { showShareModalAtom } from "../jotai";
import { useAtom } from "jotai";
import { Logo } from "../svg";

const ArtWallHeaderComponent = ({
  actions,
  artWallCanvasRef,
  artWallProducts,
  artWallScreenshotStatus,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const [showShareModal, setShowShareModal] = useAtom(showShareModalAtom);

  const MiniBag = () => {
    console.log("count AWD", count);
    return (
      <div id="close_cart_on_leave" className="h-full flex items-center">
        <div
          id="cart-contents"
          className="cart-contents trigger flex items-center ml-6 relative"
        >
          <a href={`${window.location.origin}/cart`} className="flex relative">
            <FontAwesomeIcon
              icon={["fal", "shopping-bag"]}
              className={`text-xl transition-colors ease-in-out duration-500 text-gray-light fill-current`}
            />
            <div className="absolute top-1/2 left-1/2 transform">
              {/* {count > 9
                ? <span id="cart-icon-count" className="circle" />
                : count == 0
                ? <span id="cart-icon-count" />
                : <span id="cart-icon-count" className={`number ${isFrontpage ? showBackground ? 'text-black' : 'text-white' : 'text-black'}`}>{count}</span>
              } */}
              <div
                id="cart-icon-count"
                className={`${
                  count > 0 ? "bg-gray-light" : "bg-transparent"
                } rounded-full flex justify-center items-center`}
                style={{ width: "18px", height: "18px" }}
              >
                {count > 0 && count <= 9 && (
                  <span className="number">{count}</span>
                )}
                {count > 9 && <span className="circle" />}
              </div>
            </div>
          </a>
          <div id="mini-cart" className="minicart">
            <p
              id="mini-cart-count"
              className="from-awd-header"
            >{`${count} products in bag`}</p>
            <ul className="cart_list product_list_widget">
              <li className="mini_cart_item"></li>
            </ul>
            <p className="woocommerce-mini-cart__total total">
              <span>Order total</span>
              <span className="woocommerce-Price- ">
                <bdi>
                  <span className="woocommerce-Price-currencySymbol"></span>
                </bdi>
              </span>
            </p>
            <div className="approx-price-wrapper">
              <input
                type="hidden"
                className="approx-price-hidden-field"
                {...{ "data-type": "minicart", "data-shipping": "no" }}
                value="false"
              />
              <p className="price price--approx"></p>
              <select id="currencyChanger"></select>
            </div>
            <p className="woocommerce-mini-cart__buttons buttons">
              <button
                className="button wc-forward"
                onClick={() =>
                  (window.location.href = `${window.location.origin}/cart`)
                }
              >
                View Bag
              </button>
              <button
                className="button checkout wc-forward"
                onClick={() =>
                  (window.location.href = `${window.location.origin}/checkout`)
                }
              >
                Proceed to checkout
              </button>
            </p>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    window.onresize = () => {
      setWindowSize({
        width: windowSize.innerWidth,
        height: windowSize.innerHeight,
      });
    };
  }, []);

  const handleShareAndDownload = useCallback(() => {
    if (isEmpty(artWallProducts)) {
      setShowTooltip(true);
    } else {
      actions.setShowShareAndDownloadModal(true);

      if (artWallCanvasRef.current === null) return;

      const getSharableUrlAndScreenshot = async () => {
        const baseUrl = window.location.origin;
        const urlResp = await axios.post(
          `${baseUrl}/wp-json/tpc/v1/share-art-wall`,
          {
            art_wall_value: artWallProducts,
          }
        );

        const awdUrl = window.location.href;
        // const constructedUrl = `${awdUrl}?h=${urlResp.data}`
        const constructedUrl =
          "https://theposterclub.com/art-wall-designer/?h=54be532531";
        actions.setArtWallShareableUrl(constructedUrl);

        if (artWallScreenshotStatus === "generating") {
          const screenshotResp = await axios.post(
            `https://share.theposterclub.com`,
            {
              url: constructedUrl,
              width: window.innerWidth,
              height: window.innerHeight,
            }
          );

          // const screenshotResp = await axios.post(`http://127.0.0.1:3003`, {
          //   url: constructedUrl,
          //   width: window.innerWidth,
          //   height: window.innerHeight,
          // })

          actions.setArtWallScreenshotStatus("done");

          console.log("the screen resp url", screenshotResp);

          actions.setArtWallScreenshot(`${screenshotResp.data.file}`);
        }
      };

      getSharableUrlAndScreenshot();
    }
  }, [artWallCanvasRef, artWallProducts, artWallScreenshotStatus]);

  const shareHandler = () => {
    if (showShareModal) return;

    if (isEmpty(artWallProducts)) {
      setShowTooltip(true);
    } else {
      setShowShareModal(true);
    }
  };

  return (
    <div className="w-full h-15 flex items-center justify-between flex-shrink-0 relative px-8 border-b border-solid bg-awd-dark border-gray z-50">
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <Logo />
      </div>
      <div>
        <button
          className="flex items-center uppercase text-black font-display-regular font-normal tracking-widest text-xxs"
          onClick={() => actions.setShowArtWallCloseModal(true)}
        >
          <FontAwesomeIcon
            icon={["fal", "times"]}
            className="mr-3 mb-1px text-xs"
          />
          Close Art Wall Designer
        </button>
      </div>
      <div className="flex items-center h-full">
        <Tooltip.Root
          open={open}
          onOpenChange={(value) => setShowTooltip(value)}
        >
          <Tooltip.Trigger asChild>
            <button
              className={classNames(
                "flex items-center uppercase text-black font-display-regular font-normal text-xxs tracking-widest relative"
              )}
              // onClick={() => handleShareAndDownload()}
              onClick={() => shareHandler()}
            >
              Share
            </button>
          </Tooltip.Trigger>
          <AnimatePresence>
            {showTooltip && isEmpty(artWallProducts) && (
              <Tooltip.Content sideOffset={8} asChild>
                <motion.p
                  initial="initial"
                  animate="animate"
                  exit="exit"
                  variants={fadeAnimation}
                  className="bg-black text-white font-display-regular font-normal text-xxs tracking-wider px-4 py-2 rounded-md"
                >
                  Please add art prints to the wall
                </motion.p>
              </Tooltip.Content>
            )}
          </AnimatePresence>
        </Tooltip.Root>
        <MiniBag />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const props = {
    artWallCanvasRef: state.artWall.artWallCanvasRef,
    artWallProducts: state.artWall.artWallProducts,
    artWallScreenshotStatus: state.artWall.artWallScreenshotStatus,
  };

  return props;
};

const mapDispatchToProps = (dispatch) => {
  const actions = {
    setArtWallScreenshot,
    setShowShareAndDownloadModal,
    setShowArtWallCloseModal,
    setArtWallShareableUrl,
    setArtWallScreenshotStatus,
  };

  const actionMap = {
    actions: bindActionCreators(actions, dispatch),
    dispatch,
  };

  return actionMap;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ArtWallHeaderComponent);
