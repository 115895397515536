import classNames from "classnames"
import { AnimatePresence, motion } from "framer-motion"
import { useState, useEffect, useRef } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import setArtWallProductFeed from "./../actions/artWall/setArtWallProductFeed"
import fetchProducts from './../actions/products/fetchProducts'
import fetchMoreProducts from './../actions/products/fetchMoreProducts'
import App from "../containers/App"
import { isEmpty } from "lodash"
import Filter from "../containers/Filter"
import * as Tooltip from '@radix-ui/react-tooltip';
import { fadeAnimation } from "../animations"

const ArtWallProductsSidebarComponent = ({ actions, artWallProductFeed, page, isFetching, isFetchingMore, favourites }) => {
  // console.log('favourites', favourites);
  const listInnerRef = useRef()

  const [showTooltip, setShowTooltip] = useState(false)

  const getFavourites = () => {
    return localStorage.getItem('favouriteIds') ? JSON.parse(localStorage.getItem('favouriteIds')) : []
  }

  const hasFavourites = () => {
    return !isEmpty(getFavourites())
  }

  const handleSetArtWallProductFeed = (productFeed) => {
    if (!hasFavourites() && productFeed === 'favourites') {
      setShowTooltip(true)
    } else {
      actions.setArtWallProductFeed(productFeed)
    }
  }

  // useEffect(() => {
  //   if (!hasFavourites()) {
  //     actions.setArtWallProductFeed('allArtPrints')
  //   }
  // }, [])

  useEffect(() => {
    let params = {}

    // if (artWallProductFeed === 'favourites') {
    //   let favouriteIds = getFavourites()
    //   favouriteIds = favouriteIds.map(f => f.id)
    
    //   params.favouriteIds = favouriteIds
    //   params.page = 1
    // } else {
    //   params.filterParams = {}
    //   params.page = 1
    // }
    
    if (artWallProductFeed === 'allArtPrints') {
      params.filterParams = {}
      params.page = 1
    } else {
      let favouriteIds = getFavourites()
      favouriteIds = favouriteIds.map(f => f.id)
    
      params.favouriteIds = favouriteIds
      params.page = 1
    }

    actions.fetchProducts(params)
  }, [artWallProductFeed])

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        if (!isFetching && !isFetchingMore) {
          const params = {}
          params.page = page
          params.filterParams = {}
          actions.fetchMoreProducts(params)
        }
      }
    }
  }

  return (
    <div className="flex-shrink-0 w-100 relative z-0">
      <div className="flex bg-tpc-light border-b border-solid border-gray relative z-50" style={{height: '60px'}}>
        <button
          className={classNames('font-body-regular text-black font-normal w-1/2 bg-transparent text-sm flex justify-center items-center')}
          onClick={() => handleSetArtWallProductFeed('allArtPrints')}
        >
          All Art Prints
        </button>
        <Tooltip.Root open={open} onOpenChange={value => setShowTooltip(value)}>
          <Tooltip.Trigger asChild>
            <button
              className={classNames('font-body-regular text-black font-normal w-1/2 bg-transparent text-sm flex justify-center items-center')}
              onClick={() => handleSetArtWallProductFeed('favourites')}
            >
              Favourites
              {favourites.length > 0 &&
                ` (${favourites.length})`
              }
            </button>
          </Tooltip.Trigger>
          <AnimatePresence>
            {showTooltip && !hasFavourites() &&
              <Tooltip.Content sideOffset={-12} asChild>
                <motion.p initial="initial" animate="animate" exit="exit" variants={fadeAnimation} className="bg-black text-white font-display-regular font-normal text-xxs tracking-wider px-4 py-2 rounded-md">You have no favourites</motion.p>
              </Tooltip.Content>
            }
          </AnimatePresence>
        </Tooltip.Root>
        <AnimatePresence exitBeforeEnter>
          {artWallProductFeed === 'allArtPrints' &&
            <motion.div
              key="underline"
              className="bg-black absolute left-0 w-1/2"
              style={{height: '2px', bottom: '-1px'}}
              layoutId="underline"
              transition={{duration: .35, ease: 'easeInOut'}}
            />
          }
        </AnimatePresence>
        <AnimatePresence exitBeforeEnter>
          {artWallProductFeed === 'favourites' &&
            <motion.div
              key="underline"
              className="bg-black absolute left-1/2 w-1/2"
              style={{height: '2px', bottom: '-1px'}}
              layoutId="underline"
              transition={{duration: .35, ease: 'easeInOut'}}
            />
          }
        </AnimatePresence>
      </div>
      <Filter />
      <div className="px-3 overflow-y-auto bg-white" style={{height: 'calc(100% - 60px - 44px)'}} onScroll={onScroll} ref={listInnerRef}>
        <App />
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  const props = {
    artWallProductFeed: state.artWall.artWallProductFeed,
    minimizeArtWallProductsSidebar: state.artWall.minimizeArtWallProductsSidebar,
    page: state.products.page,
    isFetching: state.products.isFetching,
    isFetchingMore: state.products.isFetchingMore,
    filters: state.filters,
    favourites: state.favourites.favourites,
  }

  return props
}

const mapDispatchToProps = (dispatch) => {
  const actions = {
    setArtWallProductFeed,
    fetchProducts,
    fetchMoreProducts,
  }

  const actionMap = {
    actions: bindActionCreators(actions, dispatch),
    dispatch,
  }

  return actionMap
}

export default connect(mapStateToProps, mapDispatchToProps)(ArtWallProductsSidebarComponent)